import { useTranslation } from 'react-i18next';
import { Message } from 'primereact/message';
import { Fragment, useMemo } from 'react';
import { AttributeValueAttributeTypeEnum, Offer, OfferFrame } from '../../api/_generated_';
import { getDownloadUrl, getOfferDisplayValue, getStructureItemLabel } from '../../utils/attribute';
import Card from '../Card';
import DataRow from '../DataRow';
import OfferDetailHeader from '../OfferDetailHeader';
import SampleRequests from '../SampleRequests';
import { PRODUCT_NAME } from '../../constants/pim-attributes';
import { tenderHasLockedAttributes } from '../../utils/helper';
import LockMessage from '../LockMessage';
import { priceCalcluationAttributes, PriceResult } from '../OfferForm/PriceCalculator/PriceCalculator';

type Props = {
  data: OfferFrame;
};

export default function OfferDetail({
  data,
}: Props) {
  const { t } = useTranslation();

  const { offer, tender, priceCalculationSection } = data;

  const hasLockedAttributes = useMemo(() => tenderHasLockedAttributes(tender), [tender]);

  return (
    <div>
      <OfferDetailHeader
        offer={data.offer as Offer}
        isEditable={data.isEditingAllowed}
      />
      { offer?.hasUnsentChanges && (
        <div className="mb-3">
          <Message severity="warn" text={t('offers.label-unsent_changes_long')} className="w-full" />
        </div>
      )}
      {hasLockedAttributes && <LockMessage />}
      <SampleRequests
        items={offer?.sampleRequests}
        productName={String(offer?.attributes?.[PRODUCT_NAME])}
      />
      { tender?.sections?.map((section) => (
        <Fragment key={`summary-title-${section.code}`}>
          <Card className="mb-5">
            <div className="flex justify-content-between mb-5">
              <h4 className="mb-0 text-xl font-normal">{section.translation}</h4>
            </div>
            { section.attributes?.map((attribute) => (
              <DataRow
                key={`summary-row-${attribute.code}`}
                label={getStructureItemLabel(attribute)}
                value={getOfferDisplayValue(offer, attribute, t)}
                lockDuration={attribute.lockDuration}
                downloadUrl={
                  getDownloadUrl(offer?.attributes?.[attribute.code] as unknown as string)
                }
                isImage={attribute.attributeType === AttributeValueAttributeTypeEnum.Image}
                isFile={attribute.attributeType === AttributeValueAttributeTypeEnum.File}
                className="mb-5"
              />
            ))}
          </Card>
          {section.code === priceCalculationSection && offer.attributes?.priceData && (
            <Card className="mb-5">
              <div className="flex justify-content-between mb-5">
                <h4 className="mb-0 text-xl font-normal">{t('prices.label-price-calculator')}</h4>
              </div>
              <PriceResult
                data={offer.attributes?.priceData}
                isLoading={false}
                attributes={priceCalcluationAttributes}
              />
            </Card>
          )}
        </Fragment>
      ))}
    </div>
  );
}
