import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '../../components/Container';
import ViewHeader from '../../components/ViewHeader';
import useCreateOffer from '../../hooks/useCreateOffer';
import useContacts from '../../hooks/useContacts';
import CreateOfferForm from '../../components/CreateOfferForm';
import NetworkContainer from '../../components/NetworkContainer';
import AppRoutes from '../../constants/routes';
import { HttpRequestStatus, Selection } from '../../components/types/types';
import useSupplierPermitNumbers from '../../hooks/useSupplierPermitNumbers';
import useListingInstructionDocument from '../../hooks/useListingInstructionDocument';

export default function CreateOffer() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { contacts, status, onSaveContact } = useContacts();

  const { data: permitNumbers, status: permitNumbersStatus } = useSupplierPermitNumbers();

  const {
    data: listingInstructionUrl,
    status: listingInstructionStatus,
  } = useListingInstructionDocument();

  const [searchParams] = useSearchParams();
  const tenderId = searchParams.get('tender') as string;
  const selection = searchParams.get('selection') as Selection;

  const {
    onCreateOffer,
    isLoading,
  } = useCreateOffer(tenderId, selection);

  useEffect(() => {
    if (!selection) {
      navigate(AppRoutes.OFFER_TYPE);
    }
  }, [selection, navigate]);

  return (
    <Container size="sm">
      <ViewHeader
        title={t('offers.label-advance-information')}
        description={t('offers.label-advance-information-description')}
      />
      <NetworkContainer
        status={[status, permitNumbersStatus, listingInstructionStatus] as HttpRequestStatus[]}
        excludeContainers
      >
        <CreateOfferForm
          onSubmit={onCreateOffer}
          onAddContact={onSaveContact}
          contacts={contacts}
          isLoading={isLoading}
          isTender={!!tenderId}
          selection={selection}
          isAgent={!!permitNumbers?.agent}
          warehouseLicenceNumber={permitNumbers?.warehouseLicenceNumber}
          listingInstructionUrl={listingInstructionUrl}
        />
      </NetworkContainer>
    </Container>
  );
}
