import { DataTable } from 'primereact/datatable';
import { useTranslation } from 'react-i18next';
import { Column } from 'primereact/column';
import { FilterItem, PurchasePrice } from '../../api/_generated_';
import { BeverageStrength } from '../types/types';
import usePurchasePriceColumns from '../../hooks/usePurchasePriceColumns';
import { formatPrice } from '../../utils/helper';

type Props = {
  items?: PurchasePrice[];
  totalRecords?: number;
  mode?: BeverageStrength;
  depositOptions?: FilterItem[];
};

export default function PurchasePriceTable({
  items,
  totalRecords,
  mode,
  depositOptions,
}: Props) {
  const { t, i18n } = useTranslation();

  const { filterColumns } = usePurchasePriceColumns();

  const priceTemplate = (row: PurchasePrice, { field }: any) => formatPrice(row[field as keyof PurchasePrice] as number, 'EUR', i18n.language);

  const depositTemplate = (row: PurchasePrice) => {
    const deposit = depositOptions?.find((item) => item.value === row.depositInfoCode);
    return deposit?.label;
  };

  const columns = [
    {
      field: 'productId', header: 'ID',
    },
    {
      field: 'productName', header: t('common.label-name'),
    },
    {
      field: 'futurePrice', header: t('purchase-prices.label-future-price'), template: priceTemplate,
    },
    {
      field: 'currentPurchasePrice', header: t('purchase-prices.label-current-purchase-price'), template: priceTemplate,
    },
    {
      field: 'currentRetailPrice', header: t('purchase-prices.label-current-retail-price'), template: priceTemplate,
    },
    {
      field: 'purchasePrice', header: t('purchase-prices.label-given-purchase-price'), template: priceTemplate,
    },
    {
      field: 'retailPrice', header: t('purchase-prices.label-retail-price'), template: priceTemplate,
    },
    {
      field: 'depositInfoCode', header: t('purchase-prices.label-deposit-info'), template: depositTemplate,
    },
  ];

  const dynamicColumns = columns
    .filter((col) => filterColumns(mode, col.field))
    .map((col) => (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={col.template}
        style={{ width: '20%' }}
      />
    ));

  const footer = (
    <span>
      {t('common.label-total-records', {
        totalRecords,
      })}
    </span>
  );

  return (
    <DataTable
      value={items}
      emptyMessage={t('common.label-no-results')}
      resizableColumns
      scrollable
      scrollHeight="800px"
      virtualScrollerOptions={{ itemSize: 57 }}
      footer={footer}
    >
      {dynamicColumns}
    </DataTable>
  );
}
