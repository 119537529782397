import React, { useEffect, useMemo, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import Container from '../../components/Container';
import ErrorMessage from '../../components/ErrorMessage';
import ViewHeader from '../../components/ViewHeader';
import useFillOffer from '../../hooks/useFillOffer';
import OfferForm from '../../components/OfferForm';
import {
  AttributeStructureGroup,
  OfferStatusEnum,
  ValidationErrors,
} from '../../api/_generated_';
import TenderSidePanel from '../../components/TenderSidePanel';
import useOfferNavigation from '../../hooks/useOfferNavigation';
import OfferSummary from '../OfferSummary';
import { HttpRequestStatus } from '../../components/types/types';
import FillOfferToolbar from '../../components/FillOfferToolbar';
import { OFFER_GROUP, PRODUCT_GROUP } from '../../constants/pim-attributes';
import LockMessage from '../../components/LockMessage';
import { tenderHasLockedAttributes } from '../../utils/helper';
import { OfferContextProvider } from '../../stores/OfferContext';

export default function FillOffer() {
  const offerFormRef = useRef<any>();

  const handleValidationErrors = (data: ValidationErrors) => {
    offerFormRef.current?.handleValidationErrors(data);
  };

  const {
    offer,
    status,
    isSaving,
    tender,
    tenderStatus,
    tenderDetail,
    showSideBar,
    isEditingAllowed,
    priceCalculationSection,
    ethylMildLimit,
    mildAlcoholLimit,
    onSubmit,
    onSendOffer,
    onDeleteOffer,
  } = useFillOffer(handleValidationErrors);

  const { t } = useTranslation();

  const {
    currentStage, stages, stageIndex, onGoBack,
  } = useOfferNavigation({
    tender,
    offer,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStage?.code]);

  const hasLockedAttributes = useMemo(() => tenderHasLockedAttributes(tender), [tender]);

  const getDescription = () => {
    if (currentStage.code === 'Analyysitiedot') {
      let description = t('offers.stage-description.analysis');
      if (offer?.attributes) {
        const group = String(offer?.attributes[PRODUCT_GROUP]);
        if (group === '780') {
          description += `\n\n${t('offers.stage-description.analysis-additional-1')}`;
        }
        const offerGroup = String(offer?.attributes[OFFER_GROUP]);
        if (offerGroup === 'Vakevat_juomat') {
          description += `\n\n${t('offers.stage-description.analysis-additional-2')}`;
        }
      }
      return description;
    }
    return '';
  };

  if (HttpRequestStatus.LOADING === status) {
    return (
      <Container size="sm" className="text-center">
        <ProgressSpinner />
      </Container>
    );
  }

  if (HttpRequestStatus.ERROR === status) {
    return (
      <Container size="sm">
        <ErrorMessage />
      </Container>
    );
  }

  if (!isEditingAllowed) {
    if (offer?.status === OfferStatusEnum.Draft) {
      return (
        <Navigate to={`/offers/detail/${offer.uuid}/`} />
      );
    }

    return (
      <Container size="sm">
        <h1>{t('offers.message-offer-no-longer-editable')}</h1>
      </Container>
    );
  }

  if (offer?.stage === 'SUMMARY') {
    return (
      <OfferSummary
        offer={offer}
        isSaving={isSaving}
        tender={tender}
        onGoBack={onGoBack}
        priceCalculationSection={priceCalculationSection}
        onSubmit={onSendOffer}
      />
    );
  }

  return (
    <OfferContextProvider config={{ mildAlcoholLimit, ethylMildLimit }} tenderDetail={tenderDetail}>
      <Container className="grid">
        <div className="col-12 xl:col-8">
          {hasLockedAttributes && <LockMessage />}
          <div className="flex flex-column-reverse gap-5 align-items-start lg:gap-3 lg:flex-row lg:justify-content-between">
            <div className="flex-1">
              <ViewHeader
                currentStage={currentStage as AttributeStructureGroup}
                stages={stages as string[]}
                description={getDescription()}
              />
            </div>
            {isEditingAllowed
              && (
                <FillOfferToolbar
                  toolbarDisabled={isSaving}
                  onDeleteOffer={onDeleteOffer}
                />
              )}
          </div>
          {(offer && currentStage) && (
            <OfferForm
              ref={offerFormRef}
              onSubmit={onSubmit}
              offer={offer}
              currentSection={currentStage as AttributeStructureGroup}
              sections={tender?.sections as AttributeStructureGroup[]}
              priceCalculationSection={priceCalculationSection}
              stageIndex={stageIndex}
              isSaving={isSaving}
              onGoBack={onGoBack}
            />
          )}
        </div>
        <div className="col-12 xl:col-4">
          {showSideBar
            && (
              <TenderSidePanel
                tender={tenderDetail}
                status={tenderStatus}
              />
            )}
        </div>
      </Container>
    </OfferContextProvider>
  );
}
