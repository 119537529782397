import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Fragment, useContext } from 'react';
import {
  AttributeValueAttributeTypeEnum, Offer, TenderFrame,
} from '../../api/_generated_';
import Card from '../../components/Card';
import Container from '../../components/Container';
import DataRow from '../../components/DataRow';
import ViewHeader from '../../components/ViewHeader';
import {
  getDownloadUrl,
  getOfferDisplayValue,
  getStructureItemLabel,
} from '../../utils/attribute';
import { AttributeConfigContext } from '../../utils/AttributeConfigProvider';
import { priceCalcluationAttributes, PriceResult } from '../../components/OfferForm/PriceCalculator/PriceCalculator';

type Props = {
  tender?: TenderFrame;
  offer?: Offer;
  priceCalculationSection: string | undefined
  isSaving?: boolean;
  onSubmit(): void;
  onGoBack(): void;
};

export default function OfferSummary({
  offer,
  tender,
  priceCalculationSection,
  isSaving,
  onGoBack,
  onSubmit,
}: Props) {
  const { isAttributeVisible } = useContext(AttributeConfigContext);

  const { t } = useTranslation();

  function showAttribute(attributeCode: string) {
    return isAttributeVisible(attributeCode, offer?.attributes as any);
  }

  if (!offer || !tender) {
    return <div />;
  }

  return (
    <Container size="sm">
      <ViewHeader
        title={t('offers.title-stage-summary')}
        description={t('offers.label-stage-summary-description')}
      />
      {tender?.sections?.map((section) => (
        <Fragment key={`summary-title-${section.code}`}>
          <Card className="mb-5">
            <div className="flex justify-content-between mb-5">
              <h4 className="mb-0 text-xl font-normal">{section.translation}</h4>
            </div>
            {section.attributes?.filter((a) => showAttribute(a.code)).map((attribute) => (
              <DataRow
                key={`summary-row-${attribute.code}`}
                label={getStructureItemLabel(attribute)}
                value={getOfferDisplayValue(offer, attribute, t)}
                downloadUrl={
                  getDownloadUrl(offer?.attributes?.[attribute.code] as unknown as string)
                }
                isImage={attribute.attributeType === AttributeValueAttributeTypeEnum.Image}
                isFile={attribute.attributeType === AttributeValueAttributeTypeEnum.File}
                lockDuration={attribute.lockDuration}
                className="mb-5"
              />
            ))}
          </Card>
          {section.code === priceCalculationSection && offer.attributes?.priceData && (
            <Card className="mb-5">
              <div className="flex justify-content-between mb-5">
                <h4 className="mb-0 text-xl font-normal">{t('prices.label-price-calculator')}</h4>
              </div>
              <PriceResult
                data={offer.attributes?.priceData}
                isLoading={false}
                attributes={priceCalcluationAttributes}
              />
            </Card>
          )}
        </Fragment>
      ))}

      <div className="flex justify-content-between">
        <Button
          text
          type="button"
          label={t('common.cmd-back')}
          icon="pi pi-arrow-left"
          iconPos="left"
          disabled={isSaving}
          onClick={onGoBack}
        />
        <Button
          type="button"
          label={t('offers.cmd-send-offer')}
          icon="pi pi-arrow-right"
          iconPos="right"
          loading={isSaving}
          onClick={onSubmit}
        />
      </div>
    </Container>
  );
}
