import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import NetworkContainer from '../../components/NetworkContainer';
import ViewHeader from '../../components/ViewHeader';
import { HttpRequestStatus } from '../../components/types/types';
import useDollyRequests from '../../hooks/useDollyRequests';
import DollyRequestTable from '../../components/DollyRequestTable';
import { DollyRequest } from '../../api/_generated_';

export default function DollyRequests() {
  const { t } = useTranslation();

  const [pageState, setPageState] = useState<PaginatorPageChangeEvent>({
    first: 0,
    rows: 10,
    page: 0,
    pageCount: 0,
  });

  const onChangePage = useCallback(
    (e: PaginatorPageChangeEvent) => {
      setPageState(e);
    },
    [],
  );

  const { data, status, isSuccess } = useDollyRequests({
    page: pageState.page,
  });

  return (
    <NetworkContainer containerSize="sm" status={status as HttpRequestStatus}>
      <ViewHeader title={t('products.title-dolly-requests')} />
      {isSuccess && (
        <DollyRequestTable
          items={data.content as DollyRequest[]}
          pageState={pageState}
          totalRecords={data.totalElements}
          onPageChange={onChangePage}
        />
      )}
    </NetworkContainer>
  );
}
