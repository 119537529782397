import { useMemo } from 'react';
import classNames from 'classnames';
import { Control, useController, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AttributeStructureItem, AttributeValueAttributeTypeEnum } from '../../api/_generated_';
import {
  AUTHORIZATION_ATTR_CODE, COUNTRY_OF_ORIGIN, PRODUCTION_AREA,
} from '../../constants/pim-attributes';
import {
  getStructureItemLabel, isSimpleSelectAttribute,
} from '../../utils/attribute';
import FormErrorMessage from '../FormErrorMessage';
import DateInput from './DateInput';
import DropdownInput from './DropdownInput';
import FileInput from './FileInput';
import MetricInput from './MetricInput';
import MultiSelectInput from './MultiSelectInput';
import NumberInput from './NumberInput';
import PriceInput from './PriceInput';
import TextAreaInput from './TextAreaInput';
import TextInput from './TextInput';
import ToggleInput from './ToggleInput';
import AuthorizationDetails from '../AuthorizationDetails';
import LockLabel from '../LockLabel';

type Props = {
  control: Control;
  watch: UseFormWatch<any>;
  required?: string | boolean;
  attribute: AttributeStructureItem;
  children?: any;
  uploadUrl?: string;
  downloadUrl?: (() => string) | string;
  name?: string;
  rules?: any;
  clearable?: boolean;
};

export default function DynamicField({
  control,
  watch,
  required,
  attribute,
  children,
  uploadUrl,
  downloadUrl,
  name,
  rules,
  clearable,
}: Props) {
  const { t } = useTranslation();

  const { field, fieldState } = useController({
    name: name || attribute.code,
    control,
    rules: {
      required,
      min: {
        value: attribute.numberMin,
        message: t('validations.message-field-min', {
          value: attribute.numberMin,
        }),
      },
      max: {
        value: attribute.numberMax,
        message: t('validations.message-field-max', {
          value: attribute.numberMax,
        }),
      },
      ...rules ?? {},
    },
  });

  let element;

  function checkRiskAreas(attr: AttributeStructureItem) {
    return (attr.code === COUNTRY_OF_ORIGIN || attr.code === PRODUCTION_AREA)
      && isSimpleSelectAttribute(attr.attributeType);
  }

  const infoText = useMemo(() => {
    if (checkRiskAreas(attribute)) {
      const selection = attribute.options?.find((option) => option.code === field.value);
      return selection?.risk
        ? t('offers.message-risk')
        : undefined;
    }

    return undefined;
  }, [field.value, attribute, t]);

  const inputProps = {
    attribute,
    field,
    fieldState,
    watch,
    error: fieldState.error?.message,
    children,
    uploadUrl,
    downloadUrl,
    infoText,
    highlight: !!infoText,
    clearable,
  };

  if (attribute.code === AUTHORIZATION_ATTR_CODE) {
    element = <AuthorizationDetails {...inputProps} />;
  } else {
    // TODO: Handle currencies and units
    switch (attribute.attributeType) {
      case AttributeValueAttributeTypeEnum.Text:
        element = <TextInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Textarea:
        element = <TextAreaInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Numeric:
        element = <NumberInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Price:
        element = <PriceInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Metric:
        element = <MetricInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Date:
        element = <DateInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Option:
      case AttributeValueAttributeTypeEnum.ReferenceEntity:
        element = <DropdownInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Options:
      case AttributeValueAttributeTypeEnum.ReferenceEntityCollection:
        element = <MultiSelectInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Yesno:
        element = <ToggleInput {...inputProps} />;
        break;
      case AttributeValueAttributeTypeEnum.Image:
      case AttributeValueAttributeTypeEnum.File:
        element = <FileInput {...inputProps} />;
        break;
      default:
        element = null;
        break;
    }
  }

  if (!element) {
    return null;
  }

  return (
    <div className="field">
      <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>
        {getStructureItemLabel(attribute)}
        <LockLabel lockDuration={attribute.lockDuration} />
      </label>
      {children ? (
        <div className="p-inputgroup">
          {element}
          {children}
        </div>
      ) : element}
      {infoText && <small className="form-field-info">{infoText}</small>}
      <FormErrorMessage label={inputProps.error} />
      {attribute.helpText && (
        <small className="block mb-3">
          {attribute.helpText}
        </small>
      )}
    </div>
  );
}
