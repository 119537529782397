import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { useTranslation } from 'react-i18next';
import { DynamicFormFieldProps } from '../../types/types';
import { getDynamicFieldTooltip } from '../../../utils/helper';
import { isAttributeDisabled } from '../../../utils/attribute';
import './ToggleInput.scss';

type Props = DynamicFormFieldProps & {
  clearable?: boolean
};

export default function ToggleInput({
  attribute,
  field,
  clearable,
}: Props) {
  const { t } = useTranslation();

  const options: SelectItem[] = [
    {
      value: true,
      label: t('common.label-yes'),
    },
    {
      value: false,
      label: t('common.label-no'),
    },
  ];

  return (
    <div>
      <SelectButton
        className="booleanSelectButton"
        unselectable={clearable}
        value={clearable ? field.value : !!field.value}
        disabled={isAttributeDisabled(attribute)}
        onChange={field.onChange}
        options={options}
        {...getDynamicFieldTooltip(attribute)}
      />
    </div>
  );
}
