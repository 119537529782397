import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useUIStore from '../stores/UIStore';
import useAxiosPrivate from './useAxiosPrivate';
import { PriceData, PriceDataParams } from '../api/_generated_';

type Props = {
  offerId: string;
  defaultValues: PriceData | null;
  callback?: (value?: number | null) => void;
};

export default function usePriceCalculator({
  offerId,
  defaultValues,
  callback,
}: Props) {
  const axios = useAxiosPrivate();

  const { t } = useTranslation();

  const { toast } = useUIStore();

  const [priceData, setPriceData] = useState<PriceData | null>(defaultValues);

  const calculatePrice = async (body: PriceDataParams): Promise<PriceData> => {
    const res = await axios.post(`/offers/${offerId}/calculate-price`, body);
    return res.data;
  };

  function onError() {
    toast.current?.show({
      severity: 'error',
      summary: t('prices.message-price-calculation-failed'),
    });

    callback?.(null);
  }

  const { isLoading, mutate } = useMutation({
    mutationFn: calculatePrice,
    onError,
    onSuccess: (data) => {
      setPriceData(data);
      callback?.(data.retailPrice);
    },
  });

  return {
    data: priceData,
    isLoading,
    mutate,
  };
}
