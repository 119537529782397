/* tslint:disable */
/* eslint-disable */
/**
 * Alko Supplierportal API
 * Alko Supplierportal
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'street': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'province'?: string;
}
/**
 * 
 * @export
 * @interface AlkoEduStatus
 */
export interface AlkoEduStatus {
    /**
     * 
     * @type {string}
     * @memberof AlkoEduStatus
     */
    'status'?: AlkoEduStatusStatusEnum;
}

export const AlkoEduStatusStatusEnum = {
    Approved: 'APPROVED',
    NotApproved: 'NOT_APPROVED',
    ConfirmRequired: 'CONFIRM_REQUIRED'
} as const;

export type AlkoEduStatusStatusEnum = typeof AlkoEduStatusStatusEnum[keyof typeof AlkoEduStatusStatusEnum];

/**
 * 
 * @export
 * @interface AllowedValues
 */
export interface AllowedValues {
    /**
     * 
     * @type {Filter}
     * @memberof AllowedValues
     */
    'filter'?: Filter;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllowedValues
     */
    'values'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AttributeFilterConfig
 */
export interface AttributeFilterConfig {
    /**
     * 
     * @type {Array<string>}
     * @memberof AttributeFilterConfig
     */
    'productGroups'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AttributeFilterConfig
     */
    'maxSelections'?: number;
    /**
     * 
     * @type {Filter}
     * @memberof AttributeFilterConfig
     */
    'filter'?: Filter;
}
/**
 * 
 * @export
 * @interface AttributeGroup
 */
export interface AttributeGroup {
    /**
     * 
     * @type {string}
     * @memberof AttributeGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeGroup
     */
    'label'?: string;
    /**
     * 
     * @type {{ [key: string]: AttributeValue; }}
     * @memberof AttributeGroup
     */
    'values'?: { [key: string]: AttributeValue; };
}
/**
 * 
 * @export
 * @interface AttributeStructureGroup
 */
export interface AttributeStructureGroup {
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureGroup
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureGroup
     */
    'translation': string;
    /**
     * 
     * @type {number}
     * @memberof AttributeStructureGroup
     */
    'sortOrder': number;
    /**
     * 
     * @type {Array<AttributeStructureItem>}
     * @memberof AttributeStructureGroup
     */
    'attributes': Array<AttributeStructureItem>;
}
/**
 * 
 * @export
 * @interface AttributeStructureItem
 */
export interface AttributeStructureItem {
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'translation': string;
    /**
     * 
     * @type {number}
     * @memberof AttributeStructureItem
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'group': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'parentCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'attributeType': AttributeStructureItemAttributeTypeEnum;
    /**
     * 
     * @type {Array<AttributeStructureOption>}
     * @memberof AttributeStructureItem
     */
    'options': Array<AttributeStructureOption>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttributeStructureItem
     */
    'allowedExtensions': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'maxFileSize': string;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeStructureItem
     */
    'negativeAllowed': boolean;
    /**
     * 
     * @type {number}
     * @memberof AttributeStructureItem
     */
    'numberMin': number;
    /**
     * 
     * @type {number}
     * @memberof AttributeStructureItem
     */
    'numberMax': number;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'metricFamily': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'metricFamilyUnit': string;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeStructureItem
     */
    'decimalsAllowed': boolean;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'dateMin': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'dateMax': string;
    /**
     * 
     * @type {number}
     * @memberof AttributeStructureItem
     */
    'maxCharacters': number;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'validationRule': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'validationRegexp': string;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeStructureItem
     */
    'required': boolean;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureItem
     */
    'helpText': string;
    /**
     * 
     * @type {object}
     * @memberof AttributeStructureItem
     */
    'value': object;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeStructureItem
     */
    'readOnly': boolean;
    /**
     * 
     * @type {number}
     * @memberof AttributeStructureItem
     */
    'lockDuration'?: number;
}

export const AttributeStructureItemAttributeTypeEnum = {
    Date: 'DATE',
    File: 'FILE',
    Identifier: 'IDENTIFIER',
    Image: 'IMAGE',
    Metric: 'METRIC',
    Numeric: 'NUMERIC',
    Option: 'OPTION',
    Options: 'OPTIONS',
    Price: 'PRICE',
    Text: 'TEXT',
    Textarea: 'TEXTAREA',
    Yesno: 'YESNO',
    Referenceoption: 'REFERENCEOPTION',
    Referenceoptions: 'REFERENCEOPTIONS',
    AssetCollection: 'ASSET_COLLECTION',
    Table: 'TABLE',
    ReferenceEntity: 'REFERENCE_ENTITY',
    ReferenceEntityCollection: 'REFERENCE_ENTITY_COLLECTION'
} as const;

export type AttributeStructureItemAttributeTypeEnum = typeof AttributeStructureItemAttributeTypeEnum[keyof typeof AttributeStructureItemAttributeTypeEnum];

/**
 * 
 * @export
 * @interface AttributeStructureOption
 */
export interface AttributeStructureOption {
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureOption
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureOption
     */
    'translation'?: string;
    /**
     * 
     * @type {string}
     * @memberof AttributeStructureOption
     */
    'parentCode'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AttributeStructureOption
     */
    'productGroups'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AttributeStructureOption
     */
    'risk'?: boolean;
}
/**
 * 
 * @export
 * @interface AttributeValue
 */
export interface AttributeValue {
    /**
     * 
     * @type {string}
     * @memberof AttributeValue
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeValue
     */
    'attributeLabel': string;
    /**
     * 
     * @type {string}
     * @memberof AttributeValue
     */
    'attributeType': AttributeValueAttributeTypeEnum;
    /**
     * 
     * @type {object}
     * @memberof AttributeValue
     */
    'data': object;
    /**
     * 
     * @type {number}
     * @memberof AttributeValue
     */
    'sortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AttributeValue
     */
    'groupSortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof AttributeValue
     */
    'lockDuration': number;
}

export const AttributeValueAttributeTypeEnum = {
    Date: 'DATE',
    File: 'FILE',
    Identifier: 'IDENTIFIER',
    Image: 'IMAGE',
    Metric: 'METRIC',
    Numeric: 'NUMERIC',
    Option: 'OPTION',
    Options: 'OPTIONS',
    Price: 'PRICE',
    Text: 'TEXT',
    Textarea: 'TEXTAREA',
    Yesno: 'YESNO',
    Referenceoption: 'REFERENCEOPTION',
    Referenceoptions: 'REFERENCEOPTIONS',
    AssetCollection: 'ASSET_COLLECTION',
    Table: 'TABLE',
    ReferenceEntity: 'REFERENCE_ENTITY',
    ReferenceEntityCollection: 'REFERENCE_ENTITY_COLLECTION'
} as const;

export type AttributeValueAttributeTypeEnum = typeof AttributeValueAttributeTypeEnum[keyof typeof AttributeValueAttributeTypeEnum];

/**
 * 
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * 
     * @type {number}
     * @memberof Contact
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Contact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface CreateDollyRequestDto
 */
export interface CreateDollyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateDollyRequestDto
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDollyRequestDto
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDollyRequestDto
     */
    'saleUnitsPerPalette'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDollyRequestDto
     */
    'contactId': string;
}
/**
 * 
 * @export
 * @interface CreateOfferInput
 */
export interface CreateOfferInput {
    /**
     * 
     * @type {string}
     * @memberof CreateOfferInput
     */
    'tenderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferInput
     */
    'target': CreateOfferInputTargetEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateOfferInput
     */
    'contactId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferInput
     */
    'useListingService'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateOfferInput
     */
    'selection': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateOfferInput
     */
    'locallyAvailableT3'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CreateOfferInput
     */
    'attributes'?: { [key: string]: object; };
}

export const CreateOfferInputTargetEnum = {
    CentralWarehouse: 'CENTRAL_WAREHOUSE',
    Store: 'STORE',
    ImportService: 'IMPORT_SERVICE',
    None: 'NONE'
} as const;

export type CreateOfferInputTargetEnum = typeof CreateOfferInputTargetEnum[keyof typeof CreateOfferInputTargetEnum];

/**
 * 
 * @export
 * @interface DashboardMessageDto
 */
export interface DashboardMessageDto {
    /**
     * 
     * @type {string}
     * @memberof DashboardMessageDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardMessageDto
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardMessageDto
     */
    'entityId': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardMessageDto
     */
    'entityType': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardMessageDto
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardMessageDto
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface DistributorChangeDto
 */
export interface DistributorChangeDto {
    /**
     * 
     * @type {number}
     * @memberof DistributorChangeDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'productName'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'status'?: DistributorChangeDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DistributorChangeDto
     */
    'purchasePrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'depositCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'authorization'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'authorizationCancellation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorChangeDto
     */
    'movesToImports'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'incotermCondition'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'loadingArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'effective'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeDto
     */
    'createdAt'?: string;
}

export const DistributorChangeDtoStatusEnum = {
    Sent: 'SENT',
    Approved: 'APPROVED',
    Rejected: 'REJECTED'
} as const;

export type DistributorChangeDtoStatusEnum = typeof DistributorChangeDtoStatusEnum[keyof typeof DistributorChangeDtoStatusEnum];

/**
 * 
 * @export
 * @interface DistributorChangeRequest
 */
export interface DistributorChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeRequest
     */
    'effective'?: string;
    /**
     * 
     * @type {number}
     * @memberof DistributorChangeRequest
     */
    'purchasePrice': number;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeRequest
     */
    'depositCode': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeRequest
     */
    'authorization': string;
    /**
     * 
     * @type {string}
     * @memberof DistributorChangeRequest
     */
    'authorizationCancellation': string;
    /**
     * 
     * @type {boolean}
     * @memberof DistributorChangeRequest
     */
    'movesToImports': boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof DistributorChangeRequest
     */
    'productionPlantIds'?: Array<number>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof DistributorChangeRequest
     */
    'dynamicValues'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface DistributorDeadlineResponse
 */
export interface DistributorDeadlineResponse {
    /**
     * 
     * @type {string}
     * @memberof DistributorDeadlineResponse
     */
    'period'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeadlineResponse
     */
    'deadline'?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributorDeadlineResponse
     */
    'effective'?: string;
}
/**
 * 
 * @export
 * @interface Dolly
 */
export interface Dolly {
    /**
     * 
     * @type {string}
     * @memberof Dolly
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof Dolly
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Dolly
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof Dolly
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface DollyRequest
 */
export interface DollyRequest {
    /**
     * 
     * @type {string}
     * @memberof DollyRequest
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequest
     */
    'status': DollyRequestStatusEnum;
    /**
     * 
     * @type {SimpleProduct}
     * @memberof DollyRequest
     */
    'product': SimpleProduct;
    /**
     * 
     * @type {number}
     * @memberof DollyRequest
     */
    'saleUnitsPerPalette': number;
    /**
     * 
     * @type {LocalizedItem}
     * @memberof DollyRequest
     */
    'type': LocalizedItem;
    /**
     * 
     * @type {string}
     * @memberof DollyRequest
     */
    'supplierId': string;
    /**
     * 
     * @type {Contact}
     * @memberof DollyRequest
     */
    'contact': Contact;
    /**
     * 
     * @type {string}
     * @memberof DollyRequest
     */
    'existingDollyId': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequest
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequest
     */
    'updatedAt': string;
}

export const DollyRequestStatusEnum = {
    Draft: 'DRAFT',
    Sent: 'SENT',
    Completed: 'COMPLETED',
    Rejected: 'REJECTED'
} as const;

export type DollyRequestStatusEnum = typeof DollyRequestStatusEnum[keyof typeof DollyRequestStatusEnum];

/**
 * 
 * @export
 * @interface DollyRequestProduct
 */
export interface DollyRequestProduct {
    /**
     * 
     * @type {string}
     * @memberof DollyRequestProduct
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequestProduct
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequestProduct
     */
    'dollySubmitDeadline': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequestProduct
     */
    'seasonalDollyAvailableSince': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequestProduct
     */
    'seasonalDollyAvailableUntil': string;
    /**
     * 
     * @type {string}
     * @memberof DollyRequestProduct
     */
    'allYearDollyAvailableSince': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DollyRequestProduct
     */
    'dollyOptionCodes': Array<string>;
}
/**
 * 
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof ErrorResponse
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'error'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'attributeCode'?: string;
    /**
     * 
     * @type {{ [key: string]: AllowedValues; }}
     * @memberof Filter
     */
    'allowedValues'?: { [key: string]: AllowedValues; };
}
/**
 * 
 * @export
 * @interface FilterItem
 */
export interface FilterItem {
    /**
     * 
     * @type {string}
     * @memberof FilterItem
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof FilterItem
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof FilterItem
     */
    'sortOrder': number;
}
/**
 * 
 * @export
 * @interface HistoryItem
 */
export interface HistoryItem {
    /**
     * 
     * @type {string}
     * @memberof HistoryItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof HistoryItem
     */
    'timestamp': string;
    /**
     * 
     * @type {Array<HistoryRow>}
     * @memberof HistoryItem
     */
    'attributes': Array<HistoryRow>;
}
/**
 * 
 * @export
 * @interface HistoryRow
 */
export interface HistoryRow {
    /**
     * 
     * @type {string}
     * @memberof HistoryRow
     */
    'attribute': string;
    /**
     * 
     * @type {string}
     * @memberof HistoryRow
     */
    'attributeType': HistoryRowAttributeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HistoryRow
     */
    'attributeLabel': string;
    /**
     * 
     * @type {object}
     * @memberof HistoryRow
     */
    'oldValue': object;
    /**
     * 
     * @type {object}
     * @memberof HistoryRow
     */
    'newValue': object;
    /**
     * 
     * @type {string}
     * @memberof HistoryRow
     */
    'locale': string;
}

export const HistoryRowAttributeTypeEnum = {
    Date: 'DATE',
    File: 'FILE',
    Identifier: 'IDENTIFIER',
    Image: 'IMAGE',
    Metric: 'METRIC',
    Numeric: 'NUMERIC',
    Option: 'OPTION',
    Options: 'OPTIONS',
    Price: 'PRICE',
    Text: 'TEXT',
    Textarea: 'TEXTAREA',
    Yesno: 'YESNO',
    Referenceoption: 'REFERENCEOPTION',
    Referenceoptions: 'REFERENCEOPTIONS',
    AssetCollection: 'ASSET_COLLECTION',
    Table: 'TABLE',
    ReferenceEntity: 'REFERENCE_ENTITY',
    ReferenceEntityCollection: 'REFERENCE_ENTITY_COLLECTION'
} as const;

export type HistoryRowAttributeTypeEnum = typeof HistoryRowAttributeTypeEnum[keyof typeof HistoryRowAttributeTypeEnum];

/**
 * 
 * @export
 * @interface LocalizedItem
 */
export interface LocalizedItem {
    /**
     * 
     * @type {string}
     * @memberof LocalizedItem
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof LocalizedItem
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {number}
     * @memberof Message
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'sender'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    'unread'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    'deletedAt'?: string;
}
/**
 * 
 * @export
 * @interface MessageRequest
 */
export interface MessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    'attachment'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageRequest
     */
    'isInternal'?: boolean;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'entityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'entityType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'unread'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'notificationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    'emailNotificationsAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    'emailTenderRemaindersAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    'emailOfInterestingTenders': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationSettings
     */
    'tenderReminderIntervalType': NotificationSettingsTenderReminderIntervalTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NotificationSettings
     */
    'tenderReminderInterval': number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    'emailOfferStatusChanges': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    'emailProductChanges': boolean;
}

export const NotificationSettingsTenderReminderIntervalTypeEnum = {
    Days: 'DAYS',
    Weeks: 'WEEKS',
    Months: 'MONTHS'
} as const;

export type NotificationSettingsTenderReminderIntervalTypeEnum = typeof NotificationSettingsTenderReminderIntervalTypeEnum[keyof typeof NotificationSettingsTenderReminderIntervalTypeEnum];

/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'offerNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'stage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'tenderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'target'?: OfferTargetEnum;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'status'?: OfferStatusEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Offer
     */
    'attributes'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<SampleRequestResource>}
     * @memberof Offer
     */
    'sampleRequests'?: Array<SampleRequestResource>;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    'unreadMessages'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    'hasUnsentChanges'?: boolean;
    /**
     * 
     * @type {Array<ProductionPlant>}
     * @memberof Offer
     */
    'productionPlants'?: Array<ProductionPlant>;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    'updatedAt'?: string;
}

export const OfferTargetEnum = {
    CentralWarehouse: 'CENTRAL_WAREHOUSE',
    Store: 'STORE',
    ImportService: 'IMPORT_SERVICE',
    None: 'NONE'
} as const;

export type OfferTargetEnum = typeof OfferTargetEnum[keyof typeof OfferTargetEnum];
export const OfferStatusEnum = {
    Draft: 'DRAFT',
    Sent: 'SENT',
    Cancelled: 'CANCELLED',
    ReturnedIncomplete: 'RETURNED_INCOMPLETE',
    SentForReEvaluation: 'SENT_FOR_RE_EVALUATION',
    SampleRequested: 'SAMPLE_REQUESTED',
    SampleLate: 'SAMPLE_LATE',
    SampleReceived: 'SAMPLE_RECEIVED',
    InEvaluation: 'IN_EVALUATION',
    Evaluated: 'EVALUATED',
    WaitingForAnalysis: 'WAITING_FOR_ANALYSIS',
    Analyzed: 'ANALYZED',
    OnReserve: 'ON_RESERVE',
    Rejected: 'REJECTED',
    Purchased: 'PURCHASED'
} as const;

export type OfferStatusEnum = typeof OfferStatusEnum[keyof typeof OfferStatusEnum];

/**
 * 
 * @export
 * @interface OfferAuthorization
 */
export interface OfferAuthorization {
    /**
     * 
     * @type {number}
     * @memberof OfferAuthorization
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferAuthorization
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OfferAuthorization
     */
    'fileId': string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferAuthorization
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferAuthorization
     */
    'authorizingParty': string;
    /**
     * 
     * @type {string}
     * @memberof OfferAuthorization
     */
    'brands': string;
    /**
     * 
     * @type {string}
     * @memberof OfferAuthorization
     */
    'additionalDetails'?: string;
}
/**
 * 
 * @export
 * @interface OfferFrame
 */
export interface OfferFrame {
    /**
     * 
     * @type {TenderFrame}
     * @memberof OfferFrame
     */
    'tender': TenderFrame;
    /**
     * 
     * @type {Offer}
     * @memberof OfferFrame
     */
    'offer': Offer;
    /**
     * 
     * @type {boolean}
     * @memberof OfferFrame
     */
    'isEditingAllowed': boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferFrame
     */
    'priceCalculationSection': string;
    /**
     * 
     * @type {number}
     * @memberof OfferFrame
     */
    'mildAlcoholLimit'?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFrame
     */
    'ethylMildLimit'?: number;
}
/**
 * 
 * @export
 * @interface OfferMessage
 */
export interface OfferMessage {
    /**
     * 
     * @type {number}
     * @memberof OfferMessage
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    'supplierId'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    'sender'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferMessage
     */
    'read'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    'attachment'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    'entityId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferMessage
     */
    'isInternal'?: boolean;
}
/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'size'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof Page
     */
    'content'?: Array<object>;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof Page
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof Page
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface PageMessage
 */
export interface PageMessage {
    /**
     * 
     * @type {number}
     * @memberof PageMessage
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageMessage
     */
    'totalElements'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessage
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessage
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageMessage
     */
    'size'?: number;
    /**
     * 
     * @type {Array<Message>}
     * @memberof PageMessage
     */
    'content'?: Array<Message>;
    /**
     * 
     * @type {number}
     * @memberof PageMessage
     */
    'number'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageMessage
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {number}
     * @memberof PageMessage
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageMessage
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageMessage
     */
    'empty'?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    'size'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Pageable
     */
    'sort'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {SortObject}
     * @memberof PageableObject
     */
    'sort'?: SortObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
}
/**
 * 
 * @export
 * @interface PermitNumberSettings
 */
export interface PermitNumberSettings {
    /**
     * 
     * @type {boolean}
     * @memberof PermitNumberSettings
     */
    'agent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PermitNumberSettings
     */
    'warehouseLicenceNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PermitNumberSettings
     */
    'valviraLicenceNumber'?: string;
}
/**
 * 
 * @export
 * @interface PriceData
 */
export interface PriceData {
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'purchasePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'centralWarehouseCosts'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'customsFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'freightFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'importServiceFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'importServicePalpaFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'suggestedBackDoorPriceInclTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'suggestedBackDoorPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'actualMarginValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'alcoholicBeverageTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'retailContainerTax'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'recyclingFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'depositValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'retailPriceWithoutVat'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'vatAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'retailPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    'retailPricePerLitre'?: number;
}
/**
 * 
 * @export
 * @interface PriceDataParams
 */
export interface PriceDataParams {
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceDataParams
     */
    'purchasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof PriceDataParams
     */
    'alcoholContent'?: number;
    /**
     * 
     * @type {number}
     * @memberof PriceDataParams
     */
    'pricingGroup'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'offerTarget'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'salesUnitsPer12Month'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'packagingTypeSpecifier'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceDataParams
     */
    'salesUnitSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'packageTax'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'customsFeeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'smallSpecialBatch'?: string;
    /**
     * 
     * @type {string}
     * @memberof PriceDataParams
     */
    'countryOfDelivery'?: string;
    /**
     * 
     * @type {number}
     * @memberof PriceDataParams
     */
    'alcoholTaxGroup'?: number;
}
/**
 * 
 * @export
 * @interface PriceQueryViewData
 */
export interface PriceQueryViewData {
    /**
     * 
     * @type {Array<AttributeStructureItem>}
     * @memberof PriceQueryViewData
     */
    'priceQueryAttributes': Array<AttributeStructureItem>;
    /**
     * 
     * @type {number}
     * @memberof PriceQueryViewData
     */
    'mildAlcoholLimit': number;
    /**
     * 
     * @type {number}
     * @memberof PriceQueryViewData
     */
    'ethylMildLimit': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PriceQueryViewData
     */
    'infoTexts': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PriceQueryViewData
     */
    'instructions'?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'parent': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'groupName': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'thumbnail': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'family': string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'unreadMessages': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    'categories': Array<string>;
    /**
     * 
     * @type {Array<AttributeGroup>}
     * @memberof Product
     */
    'details': Array<AttributeGroup>;
    /**
     * 
     * @type {Array<ProductChangeProposal>}
     * @memberof Product
     */
    'pendingChanges': Array<ProductChangeProposal>;
    /**
     * 
     * @type {Array<RejectedProposal>}
     * @memberof Product
     */
    'rejectedProposals': Array<RejectedProposal>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'dolliesMainProductId': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'lastModifiedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'changesAllowed': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'dollyDeliveryAllowed': boolean;
    /**
     * 
     * @type {Array<SampleRequestResource>}
     * @memberof Product
     */
    'sampleRequests': Array<SampleRequestResource>;
    /**
     * 
     * @type {Array<UpcomingValueChangeDto>}
     * @memberof Product
     */
    'upcomingChanges': Array<UpcomingValueChangeDto>;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'generalSelectionChangeAllowed': boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'generalSelectionChangeAllowedFrom': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'generalSelectionChangeAllowedUntil': string;
}
/**
 * 
 * @export
 * @interface ProductChangeProposal
 */
export interface ProductChangeProposal {
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'requestId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'attribute': string;
    /**
     * 
     * @type {object}
     * @memberof ProductChangeProposal
     */
    'value': object;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'locale': string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'supplier': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductChangeProposal
     */
    'editable': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductChangeProposal
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'effective': string;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeProposal
     */
    'label'?: string;
}
/**
 * 
 * @export
 * @interface ProductChangeRequest
 */
export interface ProductChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductChangeRequest
     */
    'effective': string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductChangeRequest
     */
    'body': { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface ProductChangeResponse
 */
export interface ProductChangeResponse {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductChangeResponse
     */
    'proposals': { [key: string]: object; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductChangeResponse
     */
    'values': { [key: string]: object; };
    /**
     * 
     * @type {Array<AttributeStructureGroup>}
     * @memberof ProductChangeResponse
     */
    'sections': Array<AttributeStructureGroup>;
    /**
     * 
     * @type {string}
     * @memberof ProductChangeResponse
     */
    'effective': string;
}
/**
 * 
 * @export
 * @interface ProductEOLRequest
 */
export interface ProductEOLRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductEOLRequest
     */
    'reasonCode': string;
    /**
     * 
     * @type {string}
     * @memberof ProductEOLRequest
     */
    'reasonText'?: string;
}
/**
 * 
 * @export
 * @interface ProductImportResult
 */
export interface ProductImportResult {
    /**
     * 
     * @type {string}
     * @memberof ProductImportResult
     */
    'filename'?: string;
    /**
     * 
     * @type {Array<ProductImportValidations>}
     * @memberof ProductImportResult
     */
    'validationErrors'?: Array<ProductImportValidations>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProductImportResult
     */
    'offerAttributeValues'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface ProductImportValidations
 */
export interface ProductImportValidations {
    /**
     * 
     * @type {string}
     * @memberof ProductImportValidations
     */
    'attributeCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductImportValidations
     */
    'error'?: string;
}
/**
 * 
 * @export
 * @interface ProductMessage
 */
export interface ProductMessage {
    /**
     * 
     * @type {number}
     * @memberof ProductMessage
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductMessage
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMessage
     */
    'sender'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMessage
     */
    'body'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductMessage
     */
    'unread'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductMessage
     */
    'attachment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMessage
     */
    'supplierId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductMessage
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface ProductionPlant
 */
export interface ProductionPlant {
    /**
     * 
     * @type {number}
     * @memberof ProductionPlant
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductionPlant
     */
    'supplyChainType': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionPlant
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProductionPlant
     */
    'amforiId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionPlant
     */
    'gs1Id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductionPlant
     */
    'staffCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductionPlant
     */
    'certificates'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductionPlant
     */
    'rawMaterials'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductionPlant
     */
    'rawMaterialPercentage'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionPlant
     */
    'isPublic'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductionPlant
     */
    'parentId'?: number;
    /**
     * 
     * @type {Address}
     * @memberof ProductionPlant
     */
    'address'?: Address;
}
/**
 * 
 * @export
 * @interface ProductionPlantFieldOptions
 */
export interface ProductionPlantFieldOptions {
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof ProductionPlantFieldOptions
     */
    'supplyChains'?: Array<SelectOption>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof ProductionPlantFieldOptions
     */
    'certificates'?: Array<SelectOption>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof ProductionPlantFieldOptions
     */
    'rawMaterials'?: Array<SelectOption>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof ProductionPlantFieldOptions
     */
    'countries'?: Array<SelectOption>;
    /**
     * 
     * @type {Array<SelectOption>}
     * @memberof ProductionPlantFieldOptions
     */
    'provinces'?: Array<SelectOption>;
}
/**
 * 
 * @export
 * @interface PurchasePrice
 */
export interface PurchasePrice {
    /**
     * 
     * @type {string}
     * @memberof PurchasePrice
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasePrice
     */
    'productName'?: string;
    /**
     * 
     * @type {number}
     * @memberof PurchasePrice
     */
    'futurePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasePrice
     */
    'currentPurchasePrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasePrice
     */
    'currentRetailPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof PurchasePrice
     */
    'purchasePrice': number;
    /**
     * 
     * @type {number}
     * @memberof PurchasePrice
     */
    'retailPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasePrice
     */
    'depositInfoCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PurchasePrice
     */
    'priceCalculationAllowed'?: boolean;
}
/**
 * 
 * @export
 * @interface PurchasePriceError
 */
export interface PurchasePriceError {
    /**
     * 
     * @type {number}
     * @memberof PurchasePriceError
     */
    'row'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasePriceError
     */
    'error'?: PurchasePriceErrorErrorEnum;
}

export const PurchasePriceErrorErrorEnum = {
    CalculationFailed: 'CALCULATION_FAILED',
    ProductNotFound: 'PRODUCT_NOT_FOUND',
    UnknownAlcoholPercentage: 'UNKNOWN_ALCOHOL_PERCENTAGE',
    InvalidValue: 'INVALID_VALUE',
    PriceChangeNotAllowed: 'PRICE_CHANGE_NOT_ALLOWED'
} as const;

export type PurchasePriceErrorErrorEnum = typeof PurchasePriceErrorErrorEnum[keyof typeof PurchasePriceErrorErrorEnum];

/**
 * 
 * @export
 * @interface PurchasePriceImportResult
 */
export interface PurchasePriceImportResult {
    /**
     * 
     * @type {Array<PurchasePrice>}
     * @memberof PurchasePriceImportResult
     */
    'rows'?: Array<PurchasePrice>;
    /**
     * 
     * @type {Array<PurchasePriceError>}
     * @memberof PurchasePriceImportResult
     */
    'errors'?: Array<PurchasePriceError>;
}
/**
 * 
 * @export
 * @interface PurchasePriceValidationResult
 */
export interface PurchasePriceValidationResult {
    /**
     * 
     * @type {PurchasePrice}
     * @memberof PurchasePriceValidationResult
     */
    'purchasePrice'?: PurchasePrice;
    /**
     * 
     * @type {string}
     * @memberof PurchasePriceValidationResult
     */
    'error'?: PurchasePriceValidationResultErrorEnum;
}

export const PurchasePriceValidationResultErrorEnum = {
    CalculationFailed: 'CALCULATION_FAILED',
    ProductNotFound: 'PRODUCT_NOT_FOUND',
    UnknownAlcoholPercentage: 'UNKNOWN_ALCOHOL_PERCENTAGE',
    InvalidValue: 'INVALID_VALUE',
    PriceChangeNotAllowed: 'PRICE_CHANGE_NOT_ALLOWED'
} as const;

export type PurchasePriceValidationResultErrorEnum = typeof PurchasePriceValidationResultErrorEnum[keyof typeof PurchasePriceValidationResultErrorEnum];

/**
 * 
 * @export
 * @interface PurchasePricesResponse
 */
export interface PurchasePricesResponse {
    /**
     * 
     * @type {string}
     * @memberof PurchasePricesResponse
     */
    'period'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasePricesResponse
     */
    'deadline'?: string;
    /**
     * 
     * @type {Array<FilterItem>}
     * @memberof PurchasePricesResponse
     */
    'depositOptions'?: Array<FilterItem>;
    /**
     * 
     * @type {Array<PurchasePrice>}
     * @memberof PurchasePricesResponse
     */
    'items'?: Array<PurchasePrice>;
    /**
     * 
     * @type {number}
     * @memberof PurchasePricesResponse
     */
    'totalRecords'?: number;
    /**
     * 
     * @type {string}
     * @memberof PurchasePricesResponse
     */
    'pricePeriodStarts'?: string;
    /**
     * 
     * @type {string}
     * @memberof PurchasePricesResponse
     */
    'pricePeriodEnds'?: string;
}
/**
 * 
 * @export
 * @interface RejectedProposal
 */
export interface RejectedProposal {
    /**
     * 
     * @type {string}
     * @memberof RejectedProposal
     */
    'requestId': string;
    /**
     * 
     * @type {string}
     * @memberof RejectedProposal
     */
    'processed': string;
    /**
     * 
     * @type {string}
     * @memberof RejectedProposal
     */
    'created': string;
    /**
     * 
     * @type {Array<HistoryRow>}
     * @memberof RejectedProposal
     */
    'changes': Array<HistoryRow>;
}
/**
 * 
 * @export
 * @interface SampleRequestResource
 */
export interface SampleRequestResource {
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'uuid': string;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'sampleIdentifier': string;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'sampleType': SampleRequestResourceSampleTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'entityType': SampleRequestResourceEntityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'entityId': string;
    /**
     * 
     * @type {boolean}
     * @memberof SampleRequestResource
     */
    'isReceived': boolean;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'receivedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'deliverDeadline': string;
    /**
     * 
     * @type {number}
     * @memberof SampleRequestResource
     */
    'requestedSampleQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof SampleRequestResource
     */
    'receivedSampleQuantity': number;
    /**
     * 
     * @type {boolean}
     * @memberof SampleRequestResource
     */
    'includesLayoutSample': boolean;
    /**
     * 
     * @type {string}
     * @memberof SampleRequestResource
     */
    'offerUuid': string;
}

export const SampleRequestResourceSampleTypeEnum = {
    OfferSample: 'OFFER_SAMPLE',
    OfferAdditionalSample: 'OFFER_ADDITIONAL_SAMPLE',
    PhotoSample: 'PHOTO_SAMPLE',
    SelectionChangeSample: 'SELECTION_CHANGE_SAMPLE',
    DistributionLaunchSample: 'DISTRIBUTION_LAUNCH_SAMPLE',
    QualityControlSample: 'QUALITY_CONTROL_SAMPLE',
    PalpaRegistrationSample: 'PALPA_REGISTRATION_SAMPLE',
    AccessorySample: 'ACCESSORY_SAMPLE'
} as const;

export type SampleRequestResourceSampleTypeEnum = typeof SampleRequestResourceSampleTypeEnum[keyof typeof SampleRequestResourceSampleTypeEnum];
export const SampleRequestResourceEntityTypeEnum = {
    Offer: 'OFFER',
    Product: 'PRODUCT'
} as const;

export type SampleRequestResourceEntityTypeEnum = typeof SampleRequestResourceEntityTypeEnum[keyof typeof SampleRequestResourceEntityTypeEnum];

/**
 * 
 * @export
 * @interface SaveContactRequest
 */
export interface SaveContactRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveContactRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SaveContactRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SaveContactRequest
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveContactRequest
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface SaveOfferAuhtorizationRequest
 */
export interface SaveOfferAuhtorizationRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveOfferAuhtorizationRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferAuhtorizationRequest
     */
    'fileId': string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferAuhtorizationRequest
     */
    'authorizingParty': string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferAuhtorizationRequest
     */
    'brands': string;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferAuhtorizationRequest
     */
    'additionalDetails'?: string;
}
/**
 * 
 * @export
 * @interface SaveOfferRequest
 */
export interface SaveOfferRequest {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SaveOfferRequest
     */
    'data'?: { [key: string]: object; };
    /**
     * 
     * @type {Array<number>}
     * @memberof SaveOfferRequest
     */
    'productionPlantIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SaveOfferRequest
     */
    'stage'?: string;
}
/**
 * 
 * @export
 * @interface SearchProduct
 */
export interface SearchProduct {
    /**
     * 
     * @type {string}
     * @memberof SearchProduct
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchProduct
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchProduct
     */
    'depositCode'?: string;
}
/**
 * 
 * @export
 * @interface SelectOption
 */
export interface SelectOption {
    /**
     * 
     * @type {string}
     * @memberof SelectOption
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectOption
     */
    'translation'?: string;
    /**
     * 
     * @type {string}
     * @memberof SelectOption
     */
    'parent'?: string;
}
/**
 * 
 * @export
 * @interface SelectionChangeDto
 */
export interface SelectionChangeDto {
    /**
     * 
     * @type {number}
     * @memberof SelectionChangeDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SelectionChangeDto
     */
    'productId': string;
    /**
     * 
     * @type {string}
     * @memberof SelectionChangeDto
     */
    'sampleRequestId': string;
    /**
     * 
     * @type {string}
     * @memberof SelectionChangeDto
     */
    'targetSelectionCode': string;
    /**
     * 
     * @type {string}
     * @memberof SelectionChangeDto
     */
    'status': SelectionChangeDtoStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SelectionChangeDto
     */
    'sampleReceived': boolean;
    /**
     * 
     * @type {string}
     * @memberof SelectionChangeDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SelectionChangeDto
     */
    'updatedAt': string;
}

export const SelectionChangeDtoStatusEnum = {
    Requested: 'REQUESTED',
    InProgress: 'IN_PROGRESS',
    Approved: 'APPROVED',
    Declined: 'DECLINED'
} as const;

export type SelectionChangeDtoStatusEnum = typeof SelectionChangeDtoStatusEnum[keyof typeof SelectionChangeDtoStatusEnum];

/**
 * 
 * @export
 * @interface SimpleProduct
 */
export interface SimpleProduct {
    /**
     * 
     * @type {string}
     * @memberof SimpleProduct
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProduct
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleProduct
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleProduct
     */
    'hasPendingChanges'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleProduct
     */
    'hasPendingSampleRequests'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SimpleProduct
     */
    'thumbnail'?: string;
    /**
     * 
     * @type {number}
     * @memberof SimpleProduct
     */
    'unreadMessages'?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleProduct
     */
    'vintage'?: string;
}
/**
 * 
 * @export
 * @interface SortObject
 */
export interface SortObject {
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortObject
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'externalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Supplier
     */
    'selfOperated'?: boolean;
}
/**
 * 
 * @export
 * @interface SupplierDto
 */
export interface SupplierDto {
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierDto
     */
    'code'?: string;
}
/**
 * 
 * @export
 * @interface SupplierRenewal
 */
export interface SupplierRenewal {
    /**
     * 
     * @type {number}
     * @memberof SupplierRenewal
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierRenewal
     */
    'supplierId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierRenewal
     */
    'lastRenewal'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierRenewal
     */
    'lastReminder'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierRenewal
     */
    'productIdentifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierRenewal
     */
    'attribute'?: string;
}
/**
 * 
 * @export
 * @interface SupplierRequest
 */
export interface SupplierRequest {
    /**
     * 
     * @type {string}
     * @memberof SupplierRequest
     */
    'supplierId'?: string;
}
/**
 * 
 * @export
 * @interface SupplierSettingsResponse
 */
export interface SupplierSettingsResponse {
    /**
     * 
     * @type {Array<AttributeStructureItem>}
     * @memberof SupplierSettingsResponse
     */
    'settings'?: Array<AttributeStructureItem>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SupplierSettingsResponse
     */
    'values'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface SystemMessageDto
 */
export interface SystemMessageDto {
    /**
     * 
     * @type {number}
     * @memberof SystemMessageDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageDto
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageDto
     */
    'activeFrom': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageDto
     */
    'activeTo': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SystemMessageDto
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface Tender
 */
export interface Tender {
    /**
     * 
     * @type {string}
     * @memberof Tender
     */
    'identifier'?: string;
    /**
     * 
     * @type {{ [key: string]: AttributeValue; }}
     * @memberof Tender
     */
    'values'?: { [key: string]: AttributeValue; };
    /**
     * 
     * @type {Array<TenderMessage>}
     * @memberof Tender
     */
    'messages'?: Array<TenderMessage>;
}
/**
 * 
 * @export
 * @interface TenderFrame
 */
export interface TenderFrame {
    /**
     * 
     * @type {string}
     * @memberof TenderFrame
     */
    'identifier'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderFrame
     */
    'expiresAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenderFrame
     */
    'offerEditableAfterSubmit'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenderFrame
     */
    'autoSampleRequestEnabled'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof TenderFrame
     */
    'categories'?: Array<string>;
    /**
     * 
     * @type {Array<AttributeStructureGroup>}
     * @memberof TenderFrame
     */
    'sections'?: Array<AttributeStructureGroup>;
    /**
     * 
     * @type {string}
     * @memberof TenderFrame
     */
    'sampleDeliveryDeadline'?: string;
}
/**
 * 
 * @export
 * @interface TenderListFrame
 */
export interface TenderListFrame {
    /**
     * 
     * @type {Array<TenderListItem>}
     * @memberof TenderListFrame
     */
    'tenders'?: Array<TenderListItem>;
    /**
     * 
     * @type {Array<FilterItem>}
     * @memberof TenderListFrame
     */
    'availableOfferGroups'?: Array<FilterItem>;
    /**
     * 
     * @type {Array<FilterItem>}
     * @memberof TenderListFrame
     */
    'availableProductGroups'?: Array<FilterItem>;
    /**
     * 
     * @type {Array<FilterItem>}
     * @memberof TenderListFrame
     */
    'availableCountries'?: Array<FilterItem>;
}
/**
 * 
 * @export
 * @interface TenderListItem
 */
export interface TenderListItem {
    /**
     * 
     * @type {string}
     * @memberof TenderListItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderListItem
     */
    'offerGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderListItem
     */
    'productGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderListItem
     */
    'subProductGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderListItem
     */
    'countries'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderListItem
     */
    'offeringEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TenderListItem
     */
    'hasMessages'?: boolean;
}
/**
 * 
 * @export
 * @interface TenderMessage
 */
export interface TenderMessage {
    /**
     * 
     * @type {number}
     * @memberof TenderMessage
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TenderMessage
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderMessage
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TenderMessage
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface TenderSettings
 */
export interface TenderSettings {
    /**
     * 
     * @type {Array<string>}
     * @memberof TenderSettings
     */
    'interestingProductGroups': Array<string>;
}
/**
 * 
 * @export
 * @interface UpcomingValueChangeDto
 */
export interface UpcomingValueChangeDto {
    /**
     * 
     * @type {string}
     * @memberof UpcomingValueChangeDto
     */
    'attribute': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingValueChangeDto
     */
    'locale': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingValueChangeDto
     */
    'effective': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingValueChangeDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof UpcomingValueChangeDto
     */
    'created': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'oid'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string;
    /**
     * 
     * @type {Supplier}
     * @memberof User
     */
    'supplier'?: Supplier;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'agent'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
    /**
     * 
     * @type {TenderSettings}
     * @memberof UserSettings
     */
    'tenders'?: TenderSettings;
    /**
     * 
     * @type {NotificationSettings}
     * @memberof UserSettings
     */
    'notifications'?: NotificationSettings;
}
/**
 * 
 * @export
 * @interface ValidateAndSaveFileRequest
 */
export interface ValidateAndSaveFileRequest {
    /**
     * 
     * @type {File}
     * @memberof ValidateAndSaveFileRequest
     */
    'file': File;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'params'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ValidationError
     */
    'options'?: { [key: string]: object; };
}
/**
 * 
 * @export
 * @interface ValidationErrors
 */
export interface ValidationErrors {
    /**
     * 
     * @type {{ [key: string]: ValidationError; }}
     * @memberof ValidationErrors
     */
    'errors': { [key: string]: ValidationError; };
}

/**
 * AttributeControllerApi - axios parameter creator
 * @export
 */
export const AttributeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/attributes/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDollyOptions: async (attributeCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributeCode' is not null or undefined
            assertParamExists('getAvailableDollyOptions', 'attributeCode', attributeCode)
            const localVarPath = `/api/v1/attributes/{attributeCode}/options`
                .replace(`{${"attributeCode"}}`, encodeURIComponent(String(attributeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AttributeControllerApi - functional programming interface
 * @export
 */
export const AttributeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AttributeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAttributeConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: AttributeFilterConfig; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAttributeConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableDollyOptions(attributeCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDollyOptions(attributeCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AttributeControllerApi - factory interface
 * @export
 */
export const AttributeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AttributeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAttributeConfig(options?: any): AxiosPromise<{ [key: string]: AttributeFilterConfig; }> {
            return localVarFp.getAttributeConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDollyOptions(attributeCode: string, options?: any): AxiosPromise<Array<FilterItem>> {
            return localVarFp.getAvailableDollyOptions(attributeCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AttributeControllerApi - object-oriented interface
 * @export
 * @class AttributeControllerApi
 * @extends {BaseAPI}
 */
export class AttributeControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeControllerApi
     */
    public getAttributeConfig(options?: AxiosRequestConfig) {
        return AttributeControllerApiFp(this.configuration).getAttributeConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} attributeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AttributeControllerApi
     */
    public getAvailableDollyOptions(attributeCode: string, options?: AxiosRequestConfig) {
        return AttributeControllerApiFp(this.configuration).getAvailableDollyOptions(attributeCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BarcodeControllerApi - axios parameter creator
 * @export
 */
export const BarcodeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} sampleIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serveBarcode: async (sampleIdentifier: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sampleIdentifier' is not null or undefined
            assertParamExists('serveBarcode', 'sampleIdentifier', sampleIdentifier)
            const localVarPath = `/api/v1/barcodes/{sampleIdentifier}`
                .replace(`{${"sampleIdentifier"}}`, encodeURIComponent(String(sampleIdentifier)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BarcodeControllerApi - functional programming interface
 * @export
 */
export const BarcodeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BarcodeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} sampleIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serveBarcode(sampleIdentifier: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serveBarcode(sampleIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BarcodeControllerApi - factory interface
 * @export
 */
export const BarcodeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BarcodeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} sampleIdentifier 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serveBarcode(sampleIdentifier: string, options?: any): AxiosPromise<string> {
            return localVarFp.serveBarcode(sampleIdentifier, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BarcodeControllerApi - object-oriented interface
 * @export
 * @class BarcodeControllerApi
 * @extends {BaseAPI}
 */
export class BarcodeControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} sampleIdentifier 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BarcodeControllerApi
     */
    public serveBarcode(sampleIdentifier: string, options?: AxiosRequestConfig) {
        return BarcodeControllerApiFp(this.configuration).serveBarcode(sampleIdentifier, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactControllerApi - axios parameter creator
 * @export
 */
export const ContactControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveContactRequest} saveContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact: async (saveContactRequest: SaveContactRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveContactRequest' is not null or undefined
            assertParamExists('createContact', 'saveContactRequest', saveContactRequest)
            const localVarPath = `/api/v1/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteContact', 'id', id)
            const localVarPath = `/api/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SaveContactRequest} saveContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact: async (id: number, saveContactRequest: SaveContactRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateContact', 'id', id)
            // verify required parameter 'saveContactRequest' is not null or undefined
            assertParamExists('updateContact', 'saveContactRequest', saveContactRequest)
            const localVarPath = `/api/v1/contacts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveContactRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactControllerApi - functional programming interface
 * @export
 */
export const ContactControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SaveContactRequest} saveContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContact(saveContactRequest: SaveContactRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContact(saveContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContact(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContact(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contact>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SaveContactRequest} saveContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContact(id: number, saveContactRequest: SaveContactRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContact(id, saveContactRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactControllerApi - factory interface
 * @export
 */
export const ContactControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {SaveContactRequest} saveContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContact(saveContactRequest: SaveContactRequest, options?: any): AxiosPromise<Contact> {
            return localVarFp.createContact(saveContactRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContact(id: number, options?: any): AxiosPromise<Contact> {
            return localVarFp.deleteContact(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(options?: any): AxiosPromise<Array<Contact>> {
            return localVarFp.getContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SaveContactRequest} saveContactRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContact(id: number, saveContactRequest: SaveContactRequest, options?: any): AxiosPromise<Contact> {
            return localVarFp.updateContact(id, saveContactRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactControllerApi - object-oriented interface
 * @export
 * @class ContactControllerApi
 * @extends {BaseAPI}
 */
export class ContactControllerApi extends BaseAPI {
    /**
     * 
     * @param {SaveContactRequest} saveContactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactControllerApi
     */
    public createContact(saveContactRequest: SaveContactRequest, options?: AxiosRequestConfig) {
        return ContactControllerApiFp(this.configuration).createContact(saveContactRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactControllerApi
     */
    public deleteContact(id: number, options?: AxiosRequestConfig) {
        return ContactControllerApiFp(this.configuration).deleteContact(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactControllerApi
     */
    public getContacts(options?: AxiosRequestConfig) {
        return ContactControllerApiFp(this.configuration).getContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SaveContactRequest} saveContactRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactControllerApi
     */
    public updateContact(id: number, saveContactRequest: SaveContactRequest, options?: AxiosRequestConfig) {
        return ContactControllerApiFp(this.configuration).updateContact(id, saveContactRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardControllerApi - axios parameter creator
 * @export
 */
export const DashboardControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardOffers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/pending-offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardSampleRequests: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/dashboard/sample-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardControllerApi - functional programming interface
 * @export
 */
export const DashboardControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DashboardMessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardOffers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Offer>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardOffers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardSampleRequests(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SampleRequestResource>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardSampleRequests(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardControllerApi - factory interface
 * @export
 */
export const DashboardControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardMessages(options?: any): AxiosPromise<Array<DashboardMessageDto>> {
            return localVarFp.getDashboardMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardOffers(options?: any): AxiosPromise<Array<Offer>> {
            return localVarFp.getDashboardOffers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardSampleRequests(options?: any): AxiosPromise<Array<SampleRequestResource>> {
            return localVarFp.getDashboardSampleRequests(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardControllerApi - object-oriented interface
 * @export
 * @class DashboardControllerApi
 * @extends {BaseAPI}
 */
export class DashboardControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDashboardMessages(options?: AxiosRequestConfig) {
        return DashboardControllerApiFp(this.configuration).getDashboardMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDashboardOffers(options?: AxiosRequestConfig) {
        return DashboardControllerApiFp(this.configuration).getDashboardOffers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardControllerApi
     */
    public getDashboardSampleRequests(options?: AxiosRequestConfig) {
        return DashboardControllerApiFp(this.configuration).getDashboardSampleRequests(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DistributorControllerApi - axios parameter creator
 * @export
 */
export const DistributorControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post product distributor change request
         * @param {string} productId 
         * @param {DistributorChangeRequest} distributorChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDistributorChange: async (productId: string, distributorChangeRequest: DistributorChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createDistributorChange', 'productId', productId)
            // verify required parameter 'distributorChangeRequest' is not null or undefined
            assertParamExists('createDistributorChange', 'distributorChangeRequest', distributorChangeRequest)
            const localVarPath = `/api/v1/distributor/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(distributorChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deposit options for distributor change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/distributor/deposit-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dynamic fields for distributor changes form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorChangeFormDynamicFields: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/distributor/dynamic-fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get distributor changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorChanges: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/distributor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get deadlines for distributor change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorDeadlines: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/distributor/deadlines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DistributorControllerApi - functional programming interface
 * @export
 */
export const DistributorControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DistributorControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Post product distributor change request
         * @param {string} productId 
         * @param {DistributorChangeRequest} distributorChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDistributorChange(productId: string, distributorChangeRequest: DistributorChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDistributorChange(productId, distributorChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deposit options for distributor change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepositOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dynamic fields for distributor changes form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributorChangeFormDynamicFields(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttributeStructureItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistributorChangeFormDynamicFields(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get distributor changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributorChanges(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DistributorChangeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistributorChanges(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get deadlines for distributor change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDistributorDeadlines(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DistributorDeadlineResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDistributorDeadlines(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DistributorControllerApi - factory interface
 * @export
 */
export const DistributorControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DistributorControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Post product distributor change request
         * @param {string} productId 
         * @param {DistributorChangeRequest} distributorChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDistributorChange(productId: string, distributorChangeRequest: DistributorChangeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createDistributorChange(productId, distributorChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deposit options for distributor change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositOptions(options?: any): AxiosPromise<Array<FilterItem>> {
            return localVarFp.getDepositOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dynamic fields for distributor changes form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorChangeFormDynamicFields(options?: any): AxiosPromise<Array<AttributeStructureItem>> {
            return localVarFp.getDistributorChangeFormDynamicFields(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get distributor changes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorChanges(options?: any): AxiosPromise<Array<DistributorChangeDto>> {
            return localVarFp.getDistributorChanges(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get deadlines for distributor change
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDistributorDeadlines(options?: any): AxiosPromise<DistributorDeadlineResponse> {
            return localVarFp.getDistributorDeadlines(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DistributorControllerApi - object-oriented interface
 * @export
 * @class DistributorControllerApi
 * @extends {BaseAPI}
 */
export class DistributorControllerApi extends BaseAPI {
    /**
     * 
     * @summary Post product distributor change request
     * @param {string} productId 
     * @param {DistributorChangeRequest} distributorChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorControllerApi
     */
    public createDistributorChange(productId: string, distributorChangeRequest: DistributorChangeRequest, options?: AxiosRequestConfig) {
        return DistributorControllerApiFp(this.configuration).createDistributorChange(productId, distributorChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deposit options for distributor change
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorControllerApi
     */
    public getDepositOptions(options?: AxiosRequestConfig) {
        return DistributorControllerApiFp(this.configuration).getDepositOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dynamic fields for distributor changes form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorControllerApi
     */
    public getDistributorChangeFormDynamicFields(options?: AxiosRequestConfig) {
        return DistributorControllerApiFp(this.configuration).getDistributorChangeFormDynamicFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get distributor changes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorControllerApi
     */
    public getDistributorChanges(options?: AxiosRequestConfig) {
        return DistributorControllerApiFp(this.configuration).getDistributorChanges(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get deadlines for distributor change
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DistributorControllerApi
     */
    public getDistributorDeadlines(options?: AxiosRequestConfig) {
        return DistributorControllerApiFp(this.configuration).getDistributorDeadlines(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DollyRequestControllerApi - axios parameter creator
 * @export
 */
export const DollyRequestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create dolly request
         * @param {CreateDollyRequestDto} createDollyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDollyRequest: async (createDollyRequestDto: CreateDollyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDollyRequestDto' is not null or undefined
            assertParamExists('createDollyRequest', 'createDollyRequestDto', createDollyRequestDto)
            const localVarPath = `/api/v1/dolly-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDollyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete dolly request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDollyRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDollyRequest', 'id', id)
            const localVarPath = `/api/v1/dolly-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dolly request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDollyRequest: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDollyRequest', 'id', id)
            const localVarPath = `/api/v1/dolly-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get dolly request product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDollyRequestProduct: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDollyRequestProduct', 'id', id)
            const localVarPath = `/api/v1/dolly-requests/{id}/product`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search dolly requests
         * @param {Pageable} pageable 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDollyRequests: async (pageable: Pageable, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getDollyRequests', 'pageable', pageable)
            const localVarPath = `/api/v1/dolly-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update dolly request
         * @param {string} id 
         * @param {CreateDollyRequestDto} createDollyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDollyRequest: async (id: string, createDollyRequestDto: CreateDollyRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDollyRequest', 'id', id)
            // verify required parameter 'createDollyRequestDto' is not null or undefined
            assertParamExists('updateDollyRequest', 'createDollyRequestDto', createDollyRequestDto)
            const localVarPath = `/api/v1/dolly-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDollyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DollyRequestControllerApi - functional programming interface
 * @export
 */
export const DollyRequestControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DollyRequestControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create dolly request
         * @param {CreateDollyRequestDto} createDollyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDollyRequest(createDollyRequestDto: CreateDollyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDollyRequest(createDollyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete dolly request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDollyRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDollyRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dolly request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDollyRequest(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DollyRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDollyRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get dolly request product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDollyRequestProduct(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DollyRequestProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDollyRequestProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search dolly requests
         * @param {Pageable} pageable 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDollyRequests(pageable: Pageable, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDollyRequests(pageable, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update dolly request
         * @param {string} id 
         * @param {CreateDollyRequestDto} createDollyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDollyRequest(id: string, createDollyRequestDto: CreateDollyRequestDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDollyRequest(id, createDollyRequestDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DollyRequestControllerApi - factory interface
 * @export
 */
export const DollyRequestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DollyRequestControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create dolly request
         * @param {CreateDollyRequestDto} createDollyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDollyRequest(createDollyRequestDto: CreateDollyRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.createDollyRequest(createDollyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete dolly request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDollyRequest(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDollyRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dolly request
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDollyRequest(id: string, options?: any): AxiosPromise<DollyRequest> {
            return localVarFp.getDollyRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get dolly request product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDollyRequestProduct(id: string, options?: any): AxiosPromise<DollyRequestProduct> {
            return localVarFp.getDollyRequestProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search dolly requests
         * @param {Pageable} pageable 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDollyRequests(pageable: Pageable, keyword?: string, options?: any): AxiosPromise<Page> {
            return localVarFp.getDollyRequests(pageable, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update dolly request
         * @param {string} id 
         * @param {CreateDollyRequestDto} createDollyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDollyRequest(id: string, createDollyRequestDto: CreateDollyRequestDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateDollyRequest(id, createDollyRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DollyRequestControllerApi - object-oriented interface
 * @export
 * @class DollyRequestControllerApi
 * @extends {BaseAPI}
 */
export class DollyRequestControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create dolly request
     * @param {CreateDollyRequestDto} createDollyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DollyRequestControllerApi
     */
    public createDollyRequest(createDollyRequestDto: CreateDollyRequestDto, options?: AxiosRequestConfig) {
        return DollyRequestControllerApiFp(this.configuration).createDollyRequest(createDollyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete dolly request
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DollyRequestControllerApi
     */
    public deleteDollyRequest(id: string, options?: AxiosRequestConfig) {
        return DollyRequestControllerApiFp(this.configuration).deleteDollyRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dolly request
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DollyRequestControllerApi
     */
    public getDollyRequest(id: string, options?: AxiosRequestConfig) {
        return DollyRequestControllerApiFp(this.configuration).getDollyRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get dolly request product
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DollyRequestControllerApi
     */
    public getDollyRequestProduct(id: string, options?: AxiosRequestConfig) {
        return DollyRequestControllerApiFp(this.configuration).getDollyRequestProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search dolly requests
     * @param {Pageable} pageable 
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DollyRequestControllerApi
     */
    public getDollyRequests(pageable: Pageable, keyword?: string, options?: AxiosRequestConfig) {
        return DollyRequestControllerApiFp(this.configuration).getDollyRequests(pageable, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update dolly request
     * @param {string} id 
     * @param {CreateDollyRequestDto} createDollyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DollyRequestControllerApi
     */
    public updateDollyRequest(id: string, createDollyRequestDto: CreateDollyRequestDto, options?: AxiosRequestConfig) {
        return DollyRequestControllerApiFp(this.configuration).updateDollyRequest(id, createDollyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileControllerApi - axios parameter creator
 * @export
 */
export const FileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete image. Not yet implemented
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get file
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFile', 'fileId', fileId)
            const localVarPath = `/api/v1/files/{fileId}`
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload file
         * @param {File} file 
         * @param {boolean} [useHelperAttributeValidation] 
         * @param {string} [productId] 
         * @param {boolean} [useAttributeValidation] 
         * @param {string} [attribute] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFile: async (file: File, useHelperAttributeValidation?: boolean, productId?: string, useAttributeValidation?: boolean, attribute?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveFile', 'file', file)
            const localVarPath = `/api/v1/files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (useHelperAttributeValidation !== undefined) {
                localVarQueryParameter['useHelperAttributeValidation'] = useHelperAttributeValidation;
            }

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (useAttributeValidation !== undefined) {
                localVarQueryParameter['useAttributeValidation'] = useAttributeValidation;
            }

            if (attribute !== undefined) {
                localVarQueryParameter['attribute'] = attribute;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete image. Not yet implemented
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get file
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFile(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload file
         * @param {File} file 
         * @param {boolean} [useHelperAttributeValidation] 
         * @param {string} [productId] 
         * @param {boolean} [useAttributeValidation] 
         * @param {string} [attribute] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveFile(file: File, useHelperAttributeValidation?: boolean, productId?: string, useAttributeValidation?: boolean, attribute?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveFile(file, useHelperAttributeValidation, productId, useAttributeValidation, attribute, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete image. Not yet implemented
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(options?: any): AxiosPromise<void> {
            return localVarFp.deleteFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get file
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(fileId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload file
         * @param {File} file 
         * @param {boolean} [useHelperAttributeValidation] 
         * @param {string} [productId] 
         * @param {boolean} [useAttributeValidation] 
         * @param {string} [attribute] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFile(file: File, useHelperAttributeValidation?: boolean, productId?: string, useAttributeValidation?: boolean, attribute?: string, options?: any): AxiosPromise<string> {
            return localVarFp.saveFile(file, useHelperAttributeValidation, productId, useAttributeValidation, attribute, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete image. Not yet implemented
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public deleteFile(options?: AxiosRequestConfig) {
        return FileControllerApiFp(this.configuration).deleteFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get file
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public getFile(fileId: string, options?: AxiosRequestConfig) {
        return FileControllerApiFp(this.configuration).getFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload file
     * @param {File} file 
     * @param {boolean} [useHelperAttributeValidation] 
     * @param {string} [productId] 
     * @param {boolean} [useAttributeValidation] 
     * @param {string} [attribute] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public saveFile(file: File, useHelperAttributeValidation?: boolean, productId?: string, useAttributeValidation?: boolean, attribute?: string, options?: AxiosRequestConfig) {
        return FileControllerApiFp(this.configuration).saveFile(file, useHelperAttributeValidation, productId, useAttributeValidation, attribute, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageControllerApi - axios parameter creator
 * @export
 */
export const MessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage: async (messageRequest: MessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageRequest' is not null or undefined
            assertParamExists('createMessage', 'messageRequest', messageRequest)
            const localVarPath = `/api/v1/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMessage', 'id', id)
            const localVarPath = `/api/v1/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (pageable: Pageable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getMessages', 'pageable', pageable)
            const localVarPath = `/api/v1/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/messages/unread/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessagesAsRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/messages/mark-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageControllerApi - functional programming interface
 * @export
 */
export const MessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessage(messageRequest: MessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessage(messageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(pageable: Pageable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMessages(pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadMessageCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadMessageCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markMessagesAsRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markMessagesAsRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageControllerApi - factory interface
 * @export
 */
export const MessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessage(messageRequest: MessageRequest, options?: any): AxiosPromise<Message> {
            return localVarFp.createMessage(messageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessage(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pageable} pageable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(pageable: Pageable, options?: any): AxiosPromise<PageMessage> {
            return localVarFp.getMessages(pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadMessageCount(options?: any): AxiosPromise<number> {
            return localVarFp.getUnreadMessageCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markMessagesAsRead(options?: any): AxiosPromise<void> {
            return localVarFp.markMessagesAsRead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageControllerApi - object-oriented interface
 * @export
 * @class MessageControllerApi
 * @extends {BaseAPI}
 */
export class MessageControllerApi extends BaseAPI {
    /**
     * 
     * @param {MessageRequest} messageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public createMessage(messageRequest: MessageRequest, options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).createMessage(messageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public deleteMessage(id: number, options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).deleteMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pageable} pageable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getMessages(pageable: Pageable, options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getMessages(pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public getUnreadMessageCount(options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).getUnreadMessageCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageControllerApi
     */
    public markMessagesAsRead(options?: AxiosRequestConfig) {
        return MessageControllerApiFp(this.configuration).markMessagesAsRead(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationControllerApi - axios parameter creator
 * @export
 */
export const NotificationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsRead: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('markNotificationAsRead', 'id', id)
            const localVarPath = `/api/v1/notifications/{id}/mark-as-read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationControllerApi - functional programming interface
 * @export
 */
export const NotificationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationAsRead(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationAsRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationControllerApi - factory interface
 * @export
 */
export const NotificationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(page?: number, size?: number, options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.getNotifications(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationAsRead(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.markNotificationAsRead(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationControllerApi - object-oriented interface
 * @export
 * @class NotificationControllerApi
 * @extends {BaseAPI}
 */
export class NotificationControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public getNotifications(page?: number, size?: number, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).getNotifications(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationControllerApi
     */
    public markNotificationAsRead(id: number, options?: AxiosRequestConfig) {
        return NotificationControllerApiFp(this.configuration).markNotificationAsRead(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferAuthorizationControllerApi - axios parameter creator
 * @export
 */
export const OfferAuthorizationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create authorization
         * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferAuthorization: async (saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveOfferAuhtorizationRequest' is not null or undefined
            assertParamExists('createOfferAuthorization', 'saveOfferAuhtorizationRequest', saveOfferAuhtorizationRequest)
            const localVarPath = `/api/v1/offer-authorizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOfferAuhtorizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all approved offer authorizations
         * @param {string} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferAuthorizations: async (offerId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/offer-authorizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offerId !== undefined) {
                localVarQueryParameter['offerId'] = offerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update authorization
         * @param {number} id 
         * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatgeOfferAuthorization: async (id: number, saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatgeOfferAuthorization', 'id', id)
            // verify required parameter 'saveOfferAuhtorizationRequest' is not null or undefined
            assertParamExists('updatgeOfferAuthorization', 'saveOfferAuhtorizationRequest', saveOfferAuhtorizationRequest)
            const localVarPath = `/api/v1/offer-authorizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOfferAuhtorizationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload authorization file
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAndSaveFile1: async (file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateAndSaveFile1', 'file', file)
            const localVarPath = `/api/v1/offer-authorizations/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferAuthorizationControllerApi - functional programming interface
 * @export
 */
export const OfferAuthorizationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferAuthorizationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create authorization
         * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOfferAuthorization(saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferAuthorization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOfferAuthorization(saveOfferAuhtorizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all approved offer authorizations
         * @param {string} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferAuthorizations(offerId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferAuthorization>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferAuthorizations(offerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update authorization
         * @param {number} id 
         * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatgeOfferAuthorization(id: number, saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferAuthorization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatgeOfferAuthorization(id, saveOfferAuhtorizationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload authorization file
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAndSaveFile1(file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateAndSaveFile1(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferAuthorizationControllerApi - factory interface
 * @export
 */
export const OfferAuthorizationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferAuthorizationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Create authorization
         * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOfferAuthorization(saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options?: any): AxiosPromise<OfferAuthorization> {
            return localVarFp.createOfferAuthorization(saveOfferAuhtorizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all approved offer authorizations
         * @param {string} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferAuthorizations(offerId?: string, options?: any): AxiosPromise<Array<OfferAuthorization>> {
            return localVarFp.getOfferAuthorizations(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update authorization
         * @param {number} id 
         * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatgeOfferAuthorization(id: number, saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options?: any): AxiosPromise<OfferAuthorization> {
            return localVarFp.updatgeOfferAuthorization(id, saveOfferAuhtorizationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload authorization file
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAndSaveFile1(file: File, options?: any): AxiosPromise<string> {
            return localVarFp.validateAndSaveFile1(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferAuthorizationControllerApi - object-oriented interface
 * @export
 * @class OfferAuthorizationControllerApi
 * @extends {BaseAPI}
 */
export class OfferAuthorizationControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create authorization
     * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAuthorizationControllerApi
     */
    public createOfferAuthorization(saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options?: AxiosRequestConfig) {
        return OfferAuthorizationControllerApiFp(this.configuration).createOfferAuthorization(saveOfferAuhtorizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all approved offer authorizations
     * @param {string} [offerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAuthorizationControllerApi
     */
    public getOfferAuthorizations(offerId?: string, options?: AxiosRequestConfig) {
        return OfferAuthorizationControllerApiFp(this.configuration).getOfferAuthorizations(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update authorization
     * @param {number} id 
     * @param {SaveOfferAuhtorizationRequest} saveOfferAuhtorizationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAuthorizationControllerApi
     */
    public updatgeOfferAuthorization(id: number, saveOfferAuhtorizationRequest: SaveOfferAuhtorizationRequest, options?: AxiosRequestConfig) {
        return OfferAuthorizationControllerApiFp(this.configuration).updatgeOfferAuthorization(id, saveOfferAuhtorizationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload authorization file
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferAuthorizationControllerApi
     */
    public validateAndSaveFile1(file: File, options?: AxiosRequestConfig) {
        return OfferAuthorizationControllerApiFp(this.configuration).validateAndSaveFile1(file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OfferControllerApi - axios parameter creator
 * @export
 */
export const OfferControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete offer message
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (messageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageId' is not null or undefined
            assertParamExists('_delete', 'messageId', messageId)
            const localVarPath = `/api/v1/offers/{messageId}/messages`
                .replace(`{${"messageId"}}`, encodeURIComponent(String(messageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Calculate prices for offer
         * @param {string} id 
         * @param {PriceDataParams} priceDataParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePrice: async (id: string, priceDataParams: PriceDataParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('calculatePrice', 'id', id)
            // verify required parameter 'priceDataParams' is not null or undefined
            assertParamExists('calculatePrice', 'priceDataParams', priceDataParams)
            const localVarPath = `/api/v1/offers/{id}/calculate-price`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceDataParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOffer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelOffer', 'id', id)
            const localVarPath = `/api/v1/offers/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create offer
         * @param {CreateOfferInput} createOfferInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer: async (createOfferInput: CreateOfferInput, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOfferInput' is not null or undefined
            assertParamExists('createOffer', 'createOfferInput', createOfferInput)
            const localVarPath = `/api/v1/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOfferInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete offer draft
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOffer', 'id', id)
            const localVarPath = `/api/v1/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOffer', 'id', id)
            const localVarPath = `/api/v1/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer frame
         * @param {string} id 
         * @param {boolean} [withSampleRequests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferFrame: async (id: string, withSampleRequests?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOfferFrame', 'id', id)
            const localVarPath = `/api/v1/offers/{id}/frame`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withSampleRequests !== undefined) {
                localVarQueryParameter['withSampleRequests'] = withSampleRequests;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get offer messages by offer number
         * @param {string} offerNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferMessages: async (offerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerNumber' is not null or undefined
            assertParamExists('getOfferMessages', 'offerNumber', offerNumber)
            const localVarPath = `/api/v1/offers/{offerNumber}/messages`
                .replace(`{${"offerNumber"}}`, encodeURIComponent(String(offerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search my offers
         * @param {Pageable} pageable 
         * @param {string} [keyword] 
         * @param {'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers: async (pageable: Pageable, keyword?: string, status?: 'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageable' is not null or undefined
            assertParamExists('getOffers', 'pageable', pageable)
            const localVarPath = `/api/v1/offers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Alko\'s offer messages as read
         * @param {string} offerNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsRead: async (offerNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerNumber' is not null or undefined
            assertParamExists('markAsRead', 'offerNumber', offerNumber)
            const localVarPath = `/api/v1/offers/{offerNumber}/messages/mark-as-read`
                .replace(`{${"offerNumber"}}`, encodeURIComponent(String(offerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Revert offer changes
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOfferChanges: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('revertOfferChanges', 'id', id)
            const localVarPath = `/api/v1/offers/{id}/revert-changes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save offer
         * @param {string} id 
         * @param {SaveOfferRequest} saveOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOffer: async (id: string, saveOfferRequest: SaveOfferRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveOffer', 'id', id)
            // verify required parameter 'saveOfferRequest' is not null or undefined
            assertParamExists('saveOffer', 'saveOfferRequest', saveOfferRequest)
            const localVarPath = `/api/v1/offers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveOfferRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save offer message
         * @param {string} offerNumber 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOfferMessage: async (offerNumber: string, messageRequest: MessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'offerNumber' is not null or undefined
            assertParamExists('saveOfferMessage', 'offerNumber', offerNumber)
            // verify required parameter 'messageRequest' is not null or undefined
            assertParamExists('saveOfferMessage', 'messageRequest', messageRequest)
            const localVarPath = `/api/v1/offers/{offerNumber}/messages`
                .replace(`{${"offerNumber"}}`, encodeURIComponent(String(offerNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOffer: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('sendOffer', 'id', id)
            const localVarPath = `/api/v1/offers/{id}/send`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OfferControllerApi - functional programming interface
 * @export
 */
export const OfferControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OfferControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete offer message
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(messageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Calculate prices for offer
         * @param {string} id 
         * @param {PriceDataParams} priceDataParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePrice(id: string, priceDataParams: PriceDataParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculatePrice(id, priceDataParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOffer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create offer
         * @param {CreateOfferInput} createOfferInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOffer(createOfferInput: CreateOfferInput, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOffer(createOfferInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete offer draft
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOffer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer frame
         * @param {string} id 
         * @param {boolean} [withSampleRequests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferFrame(id: string, withSampleRequests?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferFrame>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferFrame(id, withSampleRequests, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get offer messages by offer number
         * @param {string} offerNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOfferMessages(offerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OfferMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOfferMessages(offerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search my offers
         * @param {Pageable} pageable 
         * @param {string} [keyword] 
         * @param {'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOffers(pageable: Pageable, keyword?: string, status?: 'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOffers(pageable, keyword, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Alko\'s offer messages as read
         * @param {string} offerNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsRead(offerNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsRead(offerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Revert offer changes
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async revertOfferChanges(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.revertOfferChanges(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save offer
         * @param {string} id 
         * @param {SaveOfferRequest} saveOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOffer(id: string, saveOfferRequest: SaveOfferRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOffer(id, saveOfferRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save offer message
         * @param {string} offerNumber 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveOfferMessage(offerNumber: string, messageRequest: MessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OfferMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveOfferMessage(offerNumber, messageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendOffer(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Offer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendOffer(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OfferControllerApi - factory interface
 * @export
 */
export const OfferControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OfferControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete offer message
         * @param {number} messageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(messageId: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Calculate prices for offer
         * @param {string} id 
         * @param {PriceDataParams} priceDataParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePrice(id: string, priceDataParams: PriceDataParams, options?: any): AxiosPromise<PriceData> {
            return localVarFp.calculatePrice(id, priceDataParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOffer(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.cancelOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create offer
         * @param {CreateOfferInput} createOfferInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOffer(createOfferInput: CreateOfferInput, options?: any): AxiosPromise<Offer> {
            return localVarFp.createOffer(createOfferInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete offer draft
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOffer(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffer(id: string, options?: any): AxiosPromise<Offer> {
            return localVarFp.getOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer frame
         * @param {string} id 
         * @param {boolean} [withSampleRequests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferFrame(id: string, withSampleRequests?: boolean, options?: any): AxiosPromise<OfferFrame> {
            return localVarFp.getOfferFrame(id, withSampleRequests, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get offer messages by offer number
         * @param {string} offerNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOfferMessages(offerNumber: string, options?: any): AxiosPromise<Array<OfferMessage>> {
            return localVarFp.getOfferMessages(offerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search my offers
         * @param {Pageable} pageable 
         * @param {string} [keyword] 
         * @param {'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers(pageable: Pageable, keyword?: string, status?: 'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED', options?: any): AxiosPromise<Page> {
            return localVarFp.getOffers(pageable, keyword, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Alko\'s offer messages as read
         * @param {string} offerNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsRead(offerNumber: string, options?: any): AxiosPromise<void> {
            return localVarFp.markAsRead(offerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Revert offer changes
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertOfferChanges(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.revertOfferChanges(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save offer
         * @param {string} id 
         * @param {SaveOfferRequest} saveOfferRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOffer(id: string, saveOfferRequest: SaveOfferRequest, options?: any): AxiosPromise<Offer> {
            return localVarFp.saveOffer(id, saveOfferRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save offer message
         * @param {string} offerNumber 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOfferMessage(offerNumber: string, messageRequest: MessageRequest, options?: any): AxiosPromise<OfferMessage> {
            return localVarFp.saveOfferMessage(offerNumber, messageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send offer
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendOffer(id: string, options?: any): AxiosPromise<Offer> {
            return localVarFp.sendOffer(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OfferControllerApi - object-oriented interface
 * @export
 * @class OfferControllerApi
 * @extends {BaseAPI}
 */
export class OfferControllerApi extends BaseAPI {
    /**
     * 
     * @summary Delete offer message
     * @param {number} messageId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public _delete(messageId: number, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration)._delete(messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Calculate prices for offer
     * @param {string} id 
     * @param {PriceDataParams} priceDataParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public calculatePrice(id: string, priceDataParams: PriceDataParams, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).calculatePrice(id, priceDataParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public cancelOffer(id: string, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).cancelOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create offer
     * @param {CreateOfferInput} createOfferInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public createOffer(createOfferInput: CreateOfferInput, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).createOffer(createOfferInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete offer draft
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public deleteOffer(id: string, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).deleteOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public getOffer(id: string, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).getOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer frame
     * @param {string} id 
     * @param {boolean} [withSampleRequests] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public getOfferFrame(id: string, withSampleRequests?: boolean, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).getOfferFrame(id, withSampleRequests, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get offer messages by offer number
     * @param {string} offerNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public getOfferMessages(offerNumber: string, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).getOfferMessages(offerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search my offers
     * @param {Pageable} pageable 
     * @param {string} [keyword] 
     * @param {'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public getOffers(pageable: Pageable, keyword?: string, status?: 'DRAFT' | 'SENT' | 'CANCELLED' | 'RETURNED_INCOMPLETE' | 'SENT_FOR_RE_EVALUATION' | 'SAMPLE_REQUESTED' | 'SAMPLE_LATE' | 'SAMPLE_RECEIVED' | 'IN_EVALUATION' | 'EVALUATED' | 'WAITING_FOR_ANALYSIS' | 'ANALYZED' | 'ON_RESERVE' | 'REJECTED' | 'PURCHASED', options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).getOffers(pageable, keyword, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Alko\'s offer messages as read
     * @param {string} offerNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public markAsRead(offerNumber: string, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).markAsRead(offerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Revert offer changes
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public revertOfferChanges(id: string, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).revertOfferChanges(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save offer
     * @param {string} id 
     * @param {SaveOfferRequest} saveOfferRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public saveOffer(id: string, saveOfferRequest: SaveOfferRequest, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).saveOffer(id, saveOfferRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save offer message
     * @param {string} offerNumber 
     * @param {MessageRequest} messageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public saveOfferMessage(offerNumber: string, messageRequest: MessageRequest, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).saveOfferMessage(offerNumber, messageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send offer
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OfferControllerApi
     */
    public sendOffer(id: string, options?: AxiosRequestConfig) {
        return OfferControllerApiFp(this.configuration).sendOffer(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriceQueryControllerApi - axios parameter creator
 * @export
 */
export const PriceQueryControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PriceDataParams} priceDataParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceDataByQuery: async (priceDataParams: PriceDataParams, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceDataParams' is not null or undefined
            assertParamExists('getPriceDataByQuery', 'priceDataParams', priceDataParams)
            const localVarPath = `/api/v1/price-query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priceDataParams, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceQueryViewData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/price-query/attributes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceQueryControllerApi - functional programming interface
 * @export
 */
export const PriceQueryControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceQueryControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PriceDataParams} priceDataParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceDataByQuery(priceDataParams: PriceDataParams, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceDataByQuery(priceDataParams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceQueryViewData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PriceQueryViewData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceQueryViewData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceQueryControllerApi - factory interface
 * @export
 */
export const PriceQueryControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceQueryControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PriceDataParams} priceDataParams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceDataByQuery(priceDataParams: PriceDataParams, options?: any): AxiosPromise<PriceData> {
            return localVarFp.getPriceDataByQuery(priceDataParams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceQueryViewData(options?: any): AxiosPromise<PriceQueryViewData> {
            return localVarFp.getPriceQueryViewData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceQueryControllerApi - object-oriented interface
 * @export
 * @class PriceQueryControllerApi
 * @extends {BaseAPI}
 */
export class PriceQueryControllerApi extends BaseAPI {
    /**
     * 
     * @param {PriceDataParams} priceDataParams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceQueryControllerApi
     */
    public getPriceDataByQuery(priceDataParams: PriceDataParams, options?: AxiosRequestConfig) {
        return PriceQueryControllerApiFp(this.configuration).getPriceDataByQuery(priceDataParams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceQueryControllerApi
     */
    public getPriceQueryViewData(options?: AxiosRequestConfig) {
        return PriceQueryControllerApiFp(this.configuration).getPriceQueryViewData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductChangeControllerApi - axios parameter creator
 * @export
 */
export const ProductChangeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {string} proposalId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProposal: async (productId: string, proposalId: string, productChangeRequest: ProductChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createProposal', 'productId', productId)
            // verify required parameter 'proposalId' is not null or undefined
            assertParamExists('createProposal', 'proposalId', proposalId)
            // verify required parameter 'productChangeRequest' is not null or undefined
            assertParamExists('createProposal', 'productChangeRequest', productChangeRequest)
            const localVarPath = `/api/v1/product-changes/{productId}/{proposalId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"proposalId"}}`, encodeURIComponent(String(proposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProposal1: async (productId: string, productChangeRequest: ProductChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createProposal1', 'productId', productId)
            // verify required parameter 'productChangeRequest' is not null or undefined
            assertParamExists('createProposal1', 'productChangeRequest', productChangeRequest)
            const localVarPath = `/api/v1/product-changes/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product change template and values
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductChangeTemplate: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductChangeTemplate', 'productId', productId)
            const localVarPath = `/api/v1/product-changes/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product change template and values and existing proposal
         * @param {string} productId 
         * @param {string} proposalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposal: async (productId: string, proposalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProposal', 'productId', productId)
            // verify required parameter 'proposalId' is not null or undefined
            assertParamExists('getProposal', 'proposalId', proposalId)
            const localVarPath = `/api/v1/product-changes/{productId}/{proposalId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"proposalId"}}`, encodeURIComponent(String(proposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProposal: async (productId: string, productChangeRequest: ProductChangeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('validateProposal', 'productId', productId)
            // verify required parameter 'productChangeRequest' is not null or undefined
            assertParamExists('validateProposal', 'productChangeRequest', productChangeRequest)
            const localVarPath = `/api/v1/product-changes/{productId}/validate`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productChangeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductChangeControllerApi - functional programming interface
 * @export
 */
export const ProductChangeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductChangeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {string} proposalId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProposal(productId: string, proposalId: string, productChangeRequest: ProductChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProposal(productId, proposalId, productChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProposal1(productId: string, productChangeRequest: ProductChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProposal1(productId, productChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product change template and values
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductChangeTemplate(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductChangeTemplate(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product change template and values and existing proposal
         * @param {string} productId 
         * @param {string} proposalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProposal(productId: string, proposalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductChangeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProposal(productId, proposalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateProposal(productId: string, productChangeRequest: ProductChangeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateProposal(productId, productChangeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductChangeControllerApi - factory interface
 * @export
 */
export const ProductChangeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductChangeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {string} proposalId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProposal(productId: string, proposalId: string, productChangeRequest: ProductChangeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createProposal(productId, proposalId, productChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProposal1(productId: string, productChangeRequest: ProductChangeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createProposal1(productId, productChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product change template and values
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductChangeTemplate(productId: string, options?: any): AxiosPromise<ProductChangeResponse> {
            return localVarFp.getProductChangeTemplate(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product change template and values and existing proposal
         * @param {string} productId 
         * @param {string} proposalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProposal(productId: string, proposalId: string, options?: any): AxiosPromise<ProductChangeResponse> {
            return localVarFp.getProposal(productId, proposalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {ProductChangeRequest} productChangeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProposal(productId: string, productChangeRequest: ProductChangeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.validateProposal(productId, productChangeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductChangeControllerApi - object-oriented interface
 * @export
 * @class ProductChangeControllerApi
 * @extends {BaseAPI}
 */
export class ProductChangeControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} productId 
     * @param {string} proposalId 
     * @param {ProductChangeRequest} productChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductChangeControllerApi
     */
    public createProposal(productId: string, proposalId: string, productChangeRequest: ProductChangeRequest, options?: AxiosRequestConfig) {
        return ProductChangeControllerApiFp(this.configuration).createProposal(productId, proposalId, productChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {ProductChangeRequest} productChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductChangeControllerApi
     */
    public createProposal1(productId: string, productChangeRequest: ProductChangeRequest, options?: AxiosRequestConfig) {
        return ProductChangeControllerApiFp(this.configuration).createProposal1(productId, productChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product change template and values
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductChangeControllerApi
     */
    public getProductChangeTemplate(productId: string, options?: AxiosRequestConfig) {
        return ProductChangeControllerApiFp(this.configuration).getProductChangeTemplate(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product change template and values and existing proposal
     * @param {string} productId 
     * @param {string} proposalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductChangeControllerApi
     */
    public getProposal(productId: string, proposalId: string, options?: AxiosRequestConfig) {
        return ProductChangeControllerApiFp(this.configuration).getProposal(productId, proposalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {ProductChangeRequest} productChangeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductChangeControllerApi
     */
    public validateProposal(productId: string, productChangeRequest: ProductChangeRequest, options?: AxiosRequestConfig) {
        return ProductChangeControllerApiFp(this.configuration).validateProposal(productId, productChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductControllerApi - axios parameter creator
 * @export
 */
export const ProductControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download supplier document
         * @param {string} id 
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSupplierDocument1: async (id: string, attributeCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadSupplierDocument1', 'id', id)
            // verify required parameter 'attributeCode' is not null or undefined
            assertParamExists('downloadSupplierDocument1', 'attributeCode', attributeCode)
            const localVarPath = `/api/v1/products/{id}/media-files/{attributeCode}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"attributeCode"}}`, encodeURIComponent(String(attributeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProduct', 'productId', productId)
            const localVarPath = `/api/v1/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product dollies
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDollies: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductDollies', 'productId', productId)
            const localVarPath = `/api/v1/products/{productId}/dollies`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product history
         * @param {string} productId 
         * @param {string} [parent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductHistory: async (productId: string, parent?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductHistory', 'productId', productId)
            const localVarPath = `/api/v1/products/{productId}/history`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parent !== undefined) {
                localVarQueryParameter['parent'] = parent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get simple product - NOT IMPLEMENTED
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleProduct: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSimpleProduct', 'id', id)
            const localVarPath = `/api/v1/products/simple/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search products
         * @param {string} [search] 
         * @param {boolean} [hasPendingChanges] 
         * @param {boolean} [isDolly] 
         * @param {boolean} [onlyDollyPossibles] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [productNumber] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleProducts: async (search?: string, hasPendingChanges?: boolean, isDolly?: boolean, onlyDollyPossibles?: boolean, page?: number, size?: number, productNumber?: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (hasPendingChanges !== undefined) {
                localVarQueryParameter['hasPendingChanges'] = hasPendingChanges;
            }

            if (isDolly !== undefined) {
                localVarQueryParameter['isDolly'] = isDolly;
            }

            if (onlyDollyPossibles !== undefined) {
                localVarQueryParameter['onlyDollyPossibles'] = onlyDollyPossibles;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (productNumber !== undefined) {
                localVarQueryParameter['productNumber'] = productNumber;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search other suppliers products for distributor change
         * @param {string} sku 
         * @param {boolean} [ignoreOwn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProducts: async (sku: string, ignoreOwn?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sku' is not null or undefined
            assertParamExists('searchProducts', 'sku', sku)
            const localVarPath = `/api/v1/products/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }

            if (ignoreOwn !== undefined) {
                localVarQueryParameter['ignoreOwn'] = ignoreOwn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start product end of life process
         * @param {string} id 
         * @param {ProductEOLRequest} productEOLRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProductEndOfLifeProcess: async (id: string, productEOLRequest: ProductEOLRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('startProductEndOfLifeProcess', 'id', id)
            // verify required parameter 'productEOLRequest' is not null or undefined
            assertParamExists('startProductEndOfLifeProcess', 'productEOLRequest', productEOLRequest)
            const localVarPath = `/api/v1/products/{id}/end-product`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productEOLRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductControllerApi - functional programming interface
 * @export
 */
export const ProductControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download supplier document
         * @param {string} id 
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSupplierDocument1(id: string, attributeCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSupplierDocument1(id, attributeCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product dollies
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductDollies(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Dolly>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDollies(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get product history
         * @param {string} productId 
         * @param {string} [parent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductHistory(productId: string, parent?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HistoryItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductHistory(productId, parent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get simple product - NOT IMPLEMENTED
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleProduct(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SimpleProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimpleProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search products
         * @param {string} [search] 
         * @param {boolean} [hasPendingChanges] 
         * @param {boolean} [isDolly] 
         * @param {boolean} [onlyDollyPossibles] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [productNumber] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimpleProducts(search?: string, hasPendingChanges?: boolean, isDolly?: boolean, onlyDollyPossibles?: boolean, page?: number, size?: number, productNumber?: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimpleProducts(search, hasPendingChanges, isDolly, onlyDollyPossibles, page, size, productNumber, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search other suppliers products for distributor change
         * @param {string} sku 
         * @param {boolean} [ignoreOwn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchProducts(sku: string, ignoreOwn?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SearchProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchProducts(sku, ignoreOwn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Start product end of life process
         * @param {string} id 
         * @param {ProductEOLRequest} productEOLRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startProductEndOfLifeProcess(id: string, productEOLRequest: ProductEOLRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startProductEndOfLifeProcess(id, productEOLRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductControllerApi - factory interface
 * @export
 */
export const ProductControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Download supplier document
         * @param {string} id 
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSupplierDocument1(id: string, attributeCode: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadSupplierDocument1(id, attributeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(productId: string, options?: any): AxiosPromise<Product> {
            return localVarFp.getProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product dollies
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductDollies(productId: string, options?: any): AxiosPromise<Array<Dolly>> {
            return localVarFp.getProductDollies(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product history
         * @param {string} productId 
         * @param {string} [parent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductHistory(productId: string, parent?: string, options?: any): AxiosPromise<Array<HistoryItem>> {
            return localVarFp.getProductHistory(productId, parent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get simple product - NOT IMPLEMENTED
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleProduct(id: string, options?: any): AxiosPromise<SimpleProduct> {
            return localVarFp.getSimpleProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search products
         * @param {string} [search] 
         * @param {boolean} [hasPendingChanges] 
         * @param {boolean} [isDolly] 
         * @param {boolean} [onlyDollyPossibles] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [productNumber] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimpleProducts(search?: string, hasPendingChanges?: boolean, isDolly?: boolean, onlyDollyPossibles?: boolean, page?: number, size?: number, productNumber?: string, status?: string, options?: any): AxiosPromise<Page> {
            return localVarFp.getSimpleProducts(search, hasPendingChanges, isDolly, onlyDollyPossibles, page, size, productNumber, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search other suppliers products for distributor change
         * @param {string} sku 
         * @param {boolean} [ignoreOwn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchProducts(sku: string, ignoreOwn?: boolean, options?: any): AxiosPromise<Array<SearchProduct>> {
            return localVarFp.searchProducts(sku, ignoreOwn, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Start product end of life process
         * @param {string} id 
         * @param {ProductEOLRequest} productEOLRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProductEndOfLifeProcess(id: string, productEOLRequest: ProductEOLRequest, options?: any): AxiosPromise<Product> {
            return localVarFp.startProductEndOfLifeProcess(id, productEOLRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductControllerApi - object-oriented interface
 * @export
 * @class ProductControllerApi
 * @extends {BaseAPI}
 */
export class ProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download supplier document
     * @param {string} id 
     * @param {string} attributeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public downloadSupplierDocument1(id: string, attributeCode: string, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).downloadSupplierDocument1(id, attributeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProduct(productId: string, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product dollies
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProductDollies(productId: string, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProductDollies(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product history
     * @param {string} productId 
     * @param {string} [parent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getProductHistory(productId: string, parent?: string, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getProductHistory(productId, parent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get simple product - NOT IMPLEMENTED
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getSimpleProduct(id: string, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getSimpleProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search products
     * @param {string} [search] 
     * @param {boolean} [hasPendingChanges] 
     * @param {boolean} [isDolly] 
     * @param {boolean} [onlyDollyPossibles] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [productNumber] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public getSimpleProducts(search?: string, hasPendingChanges?: boolean, isDolly?: boolean, onlyDollyPossibles?: boolean, page?: number, size?: number, productNumber?: string, status?: string, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).getSimpleProducts(search, hasPendingChanges, isDolly, onlyDollyPossibles, page, size, productNumber, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search other suppliers products for distributor change
     * @param {string} sku 
     * @param {boolean} [ignoreOwn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public searchProducts(sku: string, ignoreOwn?: boolean, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).searchProducts(sku, ignoreOwn, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Start product end of life process
     * @param {string} id 
     * @param {ProductEOLRequest} productEOLRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductControllerApi
     */
    public startProductEndOfLifeProcess(id: string, productEOLRequest: ProductEOLRequest, options?: AxiosRequestConfig) {
        return ProductControllerApiFp(this.configuration).startProductEndOfLifeProcess(id, productEOLRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductExportControllerApi - axios parameter creator
 * @export
 */
export const ProductExportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/product-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductExportControllerApi - functional programming interface
 * @export
 */
export const ProductExportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductExportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductExportControllerApi - factory interface
 * @export
 */
export const ProductExportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductExportControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProducts(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportProducts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductExportControllerApi - object-oriented interface
 * @export
 * @class ProductExportControllerApi
 * @extends {BaseAPI}
 */
export class ProductExportControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductExportControllerApi
     */
    public exportProducts(options?: AxiosRequestConfig) {
        return ProductExportControllerApiFp(this.configuration).exportProducts(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductImportControllerApi - axios parameter creator
 * @export
 */
export const ProductImportControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get product template as an excel file by given tender id
         * @param {string} tenderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTemplate: async (tenderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenderId' is not null or undefined
            assertParamExists('generateTemplate', 'tenderId', tenderId)
            const localVarPath = `/api/v1/product-import/{tenderId}`
                .replace(`{${"tenderId"}}`, encodeURIComponent(String(tenderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload product offer information as an excel file. Returns map of offer attributes and validation errors
         * @param {string} tenderId 
         * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProduct: async (tenderId: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenderId' is not null or undefined
            assertParamExists('importProduct', 'tenderId', tenderId)
            const localVarPath = `/api/v1/product-import/{tenderId}`
                .replace(`{${"tenderId"}}`, encodeURIComponent(String(tenderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateAndSaveFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductImportControllerApi - functional programming interface
 * @export
 */
export const ProductImportControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductImportControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get product template as an excel file by given tender id
         * @param {string} tenderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateTemplate(tenderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateTemplate(tenderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload product offer information as an excel file. Returns map of offer attributes and validation errors
         * @param {string} tenderId 
         * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importProduct(tenderId: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importProduct(tenderId, validateAndSaveFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductImportControllerApi - factory interface
 * @export
 */
export const ProductImportControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductImportControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get product template as an excel file by given tender id
         * @param {string} tenderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTemplate(tenderId: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.generateTemplate(tenderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload product offer information as an excel file. Returns map of offer attributes and validation errors
         * @param {string} tenderId 
         * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProduct(tenderId: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options?: any): AxiosPromise<ProductImportResult> {
            return localVarFp.importProduct(tenderId, validateAndSaveFileRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductImportControllerApi - object-oriented interface
 * @export
 * @class ProductImportControllerApi
 * @extends {BaseAPI}
 */
export class ProductImportControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get product template as an excel file by given tender id
     * @param {string} tenderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImportControllerApi
     */
    public generateTemplate(tenderId: string, options?: AxiosRequestConfig) {
        return ProductImportControllerApiFp(this.configuration).generateTemplate(tenderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload product offer information as an excel file. Returns map of offer attributes and validation errors
     * @param {string} tenderId 
     * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductImportControllerApi
     */
    public importProduct(tenderId: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options?: AxiosRequestConfig) {
        return ProductImportControllerApiFp(this.configuration).importProduct(tenderId, validateAndSaveFileRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductMessageControllerApi - axios parameter creator
 * @export
 */
export const ProductMessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductMessage: async (productId: string, messageRequest: MessageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createProductMessage', 'productId', productId)
            // verify required parameter 'messageRequest' is not null or undefined
            assertParamExists('createProductMessage', 'messageRequest', messageRequest)
            const localVarPath = `/api/v1/product-messages/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductMessage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProductMessage', 'id', id)
            const localVarPath = `/api/v1/product-messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMessages: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductMessages', 'productId', productId)
            const localVarPath = `/api/v1/product-messages/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Mark Alko\'s product messages as read
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsRead1: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('markAsRead1', 'productId', productId)
            const localVarPath = `/api/v1/product-messages/{productId}/mark-as-read`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductMessageControllerApi - functional programming interface
 * @export
 */
export const ProductMessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductMessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductMessage(productId: string, messageRequest: MessageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductMessage(productId, messageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductMessage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductMessage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductMessages(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductMessage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductMessages(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Mark Alko\'s product messages as read
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markAsRead1(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markAsRead1(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductMessageControllerApi - factory interface
 * @export
 */
export const ProductMessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductMessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {MessageRequest} messageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductMessage(productId: string, messageRequest: MessageRequest, options?: any): AxiosPromise<ProductMessage> {
            return localVarFp.createProductMessage(productId, messageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductMessage(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductMessages(productId: string, options?: any): AxiosPromise<Array<ProductMessage>> {
            return localVarFp.getProductMessages(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Mark Alko\'s product messages as read
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markAsRead1(productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.markAsRead1(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductMessageControllerApi - object-oriented interface
 * @export
 * @class ProductMessageControllerApi
 * @extends {BaseAPI}
 */
export class ProductMessageControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} productId 
     * @param {MessageRequest} messageRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductMessageControllerApi
     */
    public createProductMessage(productId: string, messageRequest: MessageRequest, options?: AxiosRequestConfig) {
        return ProductMessageControllerApiFp(this.configuration).createProductMessage(productId, messageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductMessageControllerApi
     */
    public deleteProductMessage(id: number, options?: AxiosRequestConfig) {
        return ProductMessageControllerApiFp(this.configuration).deleteProductMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductMessageControllerApi
     */
    public getProductMessages(productId: string, options?: AxiosRequestConfig) {
        return ProductMessageControllerApiFp(this.configuration).getProductMessages(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Mark Alko\'s product messages as read
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductMessageControllerApi
     */
    public markAsRead1(productId: string, options?: AxiosRequestConfig) {
        return ProductMessageControllerApiFp(this.configuration).markAsRead1(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductionPlantsControllerApi - axios parameter creator
 * @export
 */
export const ProductionPlantsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProductionPlant} productionPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductionPlant: async (productionPlant: ProductionPlant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productionPlant' is not null or undefined
            assertParamExists('createProductionPlant', 'productionPlant', productionPlant)
            const localVarPath = `/api/v1/production-plants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productionPlant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductionPlant: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProductionPlant', 'id', id)
            const localVarPath = `/api/v1/production-plants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/production-plants/field-options`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionPlants: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/production-plants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionPlants1: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductionPlants1', 'id', id)
            const localVarPath = `/api/v1/production-plants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductionPlant} productionPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductionPlant: async (id: number, productionPlant: ProductionPlant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProductionPlant', 'id', id)
            // verify required parameter 'productionPlant' is not null or undefined
            assertParamExists('updateProductionPlant', 'productionPlant', productionPlant)
            const localVarPath = `/api/v1/production-plants/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productionPlant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductionPlantsControllerApi - functional programming interface
 * @export
 */
export const ProductionPlantsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductionPlantsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProductionPlant} productionPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductionPlant(productionPlant: ProductionPlant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionPlant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductionPlant(productionPlant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductionPlant(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionPlant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductionPlant(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFieldOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionPlantFieldOptions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFieldOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductionPlants(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductionPlant>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductionPlants(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductionPlants1(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionPlant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductionPlants1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductionPlant} productionPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductionPlant(id: number, productionPlant: ProductionPlant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductionPlant>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductionPlant(id, productionPlant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductionPlantsControllerApi - factory interface
 * @export
 */
export const ProductionPlantsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductionPlantsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductionPlant} productionPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductionPlant(productionPlant: ProductionPlant, options?: any): AxiosPromise<ProductionPlant> {
            return localVarFp.createProductionPlant(productionPlant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductionPlant(id: number, options?: any): AxiosPromise<ProductionPlant> {
            return localVarFp.deleteProductionPlant(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFieldOptions(options?: any): AxiosPromise<ProductionPlantFieldOptions> {
            return localVarFp.getFieldOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionPlants(options?: any): AxiosPromise<Array<ProductionPlant>> {
            return localVarFp.getProductionPlants(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductionPlants1(id: number, options?: any): AxiosPromise<ProductionPlant> {
            return localVarFp.getProductionPlants1(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ProductionPlant} productionPlant 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductionPlant(id: number, productionPlant: ProductionPlant, options?: any): AxiosPromise<ProductionPlant> {
            return localVarFp.updateProductionPlant(id, productionPlant, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductionPlantsControllerApi - object-oriented interface
 * @export
 * @class ProductionPlantsControllerApi
 * @extends {BaseAPI}
 */
export class ProductionPlantsControllerApi extends BaseAPI {
    /**
     * 
     * @param {ProductionPlant} productionPlant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionPlantsControllerApi
     */
    public createProductionPlant(productionPlant: ProductionPlant, options?: AxiosRequestConfig) {
        return ProductionPlantsControllerApiFp(this.configuration).createProductionPlant(productionPlant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionPlantsControllerApi
     */
    public deleteProductionPlant(id: number, options?: AxiosRequestConfig) {
        return ProductionPlantsControllerApiFp(this.configuration).deleteProductionPlant(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionPlantsControllerApi
     */
    public getFieldOptions(options?: AxiosRequestConfig) {
        return ProductionPlantsControllerApiFp(this.configuration).getFieldOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionPlantsControllerApi
     */
    public getProductionPlants(options?: AxiosRequestConfig) {
        return ProductionPlantsControllerApiFp(this.configuration).getProductionPlants(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionPlantsControllerApi
     */
    public getProductionPlants1(id: number, options?: AxiosRequestConfig) {
        return ProductionPlantsControllerApiFp(this.configuration).getProductionPlants1(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ProductionPlant} productionPlant 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductionPlantsControllerApi
     */
    public updateProductionPlant(id: number, productionPlant: ProductionPlant, options?: AxiosRequestConfig) {
        return ProductionPlantsControllerApiFp(this.configuration).updateProductionPlant(id, productionPlant, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PurchasePricesControllerApi - axios parameter creator
 * @export
 */
export const PurchasePricesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PurchasePrice} purchasePrice 
         * @param {string} [pricingDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePrices: async (purchasePrice: PurchasePrice, pricingDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchasePrice' is not null or undefined
            assertParamExists('calculatePrices', 'purchasePrice', purchasePrice)
            const localVarPath = `/api/v1/purchase-prices/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pricingDate !== undefined) {
                localVarQueryParameter['pricingDate'] = pricingDate;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchasePrice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get export purchase price report. Allowed values are mild or strong
         * @param {string} [beverageStrength] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPurchasePrices: async (beverageStrength?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/purchase-prices/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (beverageStrength !== undefined) {
                localVarQueryParameter['beverageStrength'] = beverageStrength;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get purchase price report template. Allowed values are mild or strong
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasePriceReportTemplate: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getPurchasePriceReportTemplate', 'code', code)
            const localVarPath = `/api/v1/purchase-prices/template/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [listAll] 
         * @param {string} [beverageStrength] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasePrices: async (listAll?: boolean, beverageStrength?: string, search?: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/purchase-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (listAll !== undefined) {
                localVarQueryParameter['listAll'] = listAll;
            }

            if (beverageStrength !== undefined) {
                localVarQueryParameter['beverageStrength'] = beverageStrength;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} beverageStrength 
         * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPurchasePrices: async (beverageStrength: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'beverageStrength' is not null or undefined
            assertParamExists('importPurchasePrices', 'beverageStrength', beverageStrength)
            const localVarPath = `/api/v1/purchase-prices/import/{beverageStrength}`
                .replace(`{${"beverageStrength"}}`, encodeURIComponent(String(beverageStrength)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateAndSaveFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<PurchasePrice>} purchasePrice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePurchasePrices: async (purchasePrice: Array<PurchasePrice>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchasePrice' is not null or undefined
            assertParamExists('savePurchasePrices', 'purchasePrice', purchasePrice)
            const localVarPath = `/api/v1/purchase-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchasePrice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasePricesControllerApi - functional programming interface
 * @export
 */
export const PurchasePricesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchasePricesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PurchasePrice} purchasePrice 
         * @param {string} [pricingDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePrices(purchasePrice: PurchasePrice, pricingDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchasePriceValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculatePrices(purchasePrice, pricingDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get export purchase price report. Allowed values are mild or strong
         * @param {string} [beverageStrength] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportPurchasePrices(beverageStrength?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportPurchasePrices(beverageStrength, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get purchase price report template. Allowed values are mild or strong
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPurchasePriceReportTemplate(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPurchasePriceReportTemplate(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [listAll] 
         * @param {string} [beverageStrength] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPurchasePrices(listAll?: boolean, beverageStrength?: string, search?: string, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchasePricesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPurchasePrices(listAll, beverageStrength, search, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} beverageStrength 
         * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importPurchasePrices(beverageStrength: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchasePriceImportResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importPurchasePrices(beverageStrength, validateAndSaveFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<PurchasePrice>} purchasePrice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePurchasePrices(purchasePrice: Array<PurchasePrice>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePurchasePrices(purchasePrice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchasePricesControllerApi - factory interface
 * @export
 */
export const PurchasePricesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchasePricesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PurchasePrice} purchasePrice 
         * @param {string} [pricingDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePrices(purchasePrice: PurchasePrice, pricingDate?: string, options?: any): AxiosPromise<PurchasePriceValidationResult> {
            return localVarFp.calculatePrices(purchasePrice, pricingDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get export purchase price report. Allowed values are mild or strong
         * @param {string} [beverageStrength] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPurchasePrices(beverageStrength?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.exportPurchasePrices(beverageStrength, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get purchase price report template. Allowed values are mild or strong
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasePriceReportTemplate(code: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getPurchasePriceReportTemplate(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [listAll] 
         * @param {string} [beverageStrength] 
         * @param {string} [search] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasePrices(listAll?: boolean, beverageStrength?: string, search?: string, page?: number, size?: number, options?: any): AxiosPromise<PurchasePricesResponse> {
            return localVarFp.getPurchasePrices(listAll, beverageStrength, search, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} beverageStrength 
         * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importPurchasePrices(beverageStrength: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options?: any): AxiosPromise<PurchasePriceImportResult> {
            return localVarFp.importPurchasePrices(beverageStrength, validateAndSaveFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<PurchasePrice>} purchasePrice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePurchasePrices(purchasePrice: Array<PurchasePrice>, options?: any): AxiosPromise<void> {
            return localVarFp.savePurchasePrices(purchasePrice, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchasePricesControllerApi - object-oriented interface
 * @export
 * @class PurchasePricesControllerApi
 * @extends {BaseAPI}
 */
export class PurchasePricesControllerApi extends BaseAPI {
    /**
     * 
     * @param {PurchasePrice} purchasePrice 
     * @param {string} [pricingDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePricesControllerApi
     */
    public calculatePrices(purchasePrice: PurchasePrice, pricingDate?: string, options?: AxiosRequestConfig) {
        return PurchasePricesControllerApiFp(this.configuration).calculatePrices(purchasePrice, pricingDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get export purchase price report. Allowed values are mild or strong
     * @param {string} [beverageStrength] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePricesControllerApi
     */
    public exportPurchasePrices(beverageStrength?: string, options?: AxiosRequestConfig) {
        return PurchasePricesControllerApiFp(this.configuration).exportPurchasePrices(beverageStrength, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get purchase price report template. Allowed values are mild or strong
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePricesControllerApi
     */
    public getPurchasePriceReportTemplate(code: string, options?: AxiosRequestConfig) {
        return PurchasePricesControllerApiFp(this.configuration).getPurchasePriceReportTemplate(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [listAll] 
     * @param {string} [beverageStrength] 
     * @param {string} [search] 
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePricesControllerApi
     */
    public getPurchasePrices(listAll?: boolean, beverageStrength?: string, search?: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PurchasePricesControllerApiFp(this.configuration).getPurchasePrices(listAll, beverageStrength, search, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} beverageStrength 
     * @param {ValidateAndSaveFileRequest} [validateAndSaveFileRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePricesControllerApi
     */
    public importPurchasePrices(beverageStrength: string, validateAndSaveFileRequest?: ValidateAndSaveFileRequest, options?: AxiosRequestConfig) {
        return PurchasePricesControllerApiFp(this.configuration).importPurchasePrices(beverageStrength, validateAndSaveFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<PurchasePrice>} purchasePrice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasePricesControllerApi
     */
    public savePurchasePrices(purchasePrice: Array<PurchasePrice>, options?: AxiosRequestConfig) {
        return PurchasePricesControllerApiFp(this.configuration).savePurchasePrices(purchasePrice, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SelectionChangeControllerApi - axios parameter creator
 * @export
 */
export const SelectionChangeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSelectionChangeRequest: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('createSelectionChangeRequest', 'productId', productId)
            const localVarPath = `/api/v1/selection-changes/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectionChangeRequests: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getSelectionChangeRequests', 'productId', productId)
            const localVarPath = `/api/v1/selection-changes/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SelectionChangeControllerApi - functional programming interface
 * @export
 */
export const SelectionChangeControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SelectionChangeControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSelectionChangeRequest(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionChangeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSelectionChangeRequest(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSelectionChangeRequests(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectionChangeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSelectionChangeRequests(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SelectionChangeControllerApi - factory interface
 * @export
 */
export const SelectionChangeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SelectionChangeControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSelectionChangeRequest(productId: string, options?: any): AxiosPromise<SelectionChangeDto> {
            return localVarFp.createSelectionChangeRequest(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSelectionChangeRequests(productId: string, options?: any): AxiosPromise<Array<SelectionChangeDto>> {
            return localVarFp.getSelectionChangeRequests(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SelectionChangeControllerApi - object-oriented interface
 * @export
 * @class SelectionChangeControllerApi
 * @extends {BaseAPI}
 */
export class SelectionChangeControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectionChangeControllerApi
     */
    public createSelectionChangeRequest(productId: string, options?: AxiosRequestConfig) {
        return SelectionChangeControllerApiFp(this.configuration).createSelectionChangeRequest(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SelectionChangeControllerApi
     */
    public getSelectionChangeRequests(productId: string, options?: AxiosRequestConfig) {
        return SelectionChangeControllerApiFp(this.configuration).getSelectionChangeRequests(productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupplierControllerApi - axios parameter creator
 * @export
 */
export const SupplierControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSuppliers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplierControllerApi - functional programming interface
 * @export
 */
export const SupplierControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplierControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableSuppliers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupplierDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableSuppliers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupplierControllerApi - factory interface
 * @export
 */
export const SupplierControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplierControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableSuppliers(options?: any): AxiosPromise<Array<SupplierDto>> {
            return localVarFp.getAvailableSuppliers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupplierControllerApi - object-oriented interface
 * @export
 * @class SupplierControllerApi
 * @extends {BaseAPI}
 */
export class SupplierControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierControllerApi
     */
    public getAvailableSuppliers(options?: AxiosRequestConfig) {
        return SupplierControllerApiFp(this.configuration).getAvailableSuppliers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupplierDocumentControllerApi - axios parameter creator
 * @export
 */
export const SupplierDocumentControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download supplier document
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSupplierDocument: async (attributeCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributeCode' is not null or undefined
            assertParamExists('downloadSupplierDocument', 'attributeCode', attributeCode)
            const localVarPath = `/api/v1/supplier-documents/{attributeCode}/preview`
                .replace(`{${"attributeCode"}}`, encodeURIComponent(String(attributeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save supplier document
         * @param {string} attributeCode 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSupplierDocument: async (attributeCode: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attributeCode' is not null or undefined
            assertParamExists('saveSupplierDocument', 'attributeCode', attributeCode)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('saveSupplierDocument', 'file', file)
            const localVarPath = `/api/v1/supplier-documents/{attributeCode}`
                .replace(`{${"attributeCode"}}`, encodeURIComponent(String(attributeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplierDocumentControllerApi - functional programming interface
 * @export
 */
export const SupplierDocumentControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplierDocumentControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download supplier document
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadSupplierDocument(attributeCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadSupplierDocument(attributeCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save supplier document
         * @param {string} attributeCode 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSupplierDocument(attributeCode: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSupplierDocument(attributeCode, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupplierDocumentControllerApi - factory interface
 * @export
 */
export const SupplierDocumentControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplierDocumentControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Download supplier document
         * @param {string} attributeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSupplierDocument(attributeCode: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadSupplierDocument(attributeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save supplier document
         * @param {string} attributeCode 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSupplierDocument(attributeCode: string, file: File, options?: any): AxiosPromise<string> {
            return localVarFp.saveSupplierDocument(attributeCode, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupplierDocumentControllerApi - object-oriented interface
 * @export
 * @class SupplierDocumentControllerApi
 * @extends {BaseAPI}
 */
export class SupplierDocumentControllerApi extends BaseAPI {
    /**
     * 
     * @summary Download supplier document
     * @param {string} attributeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierDocumentControllerApi
     */
    public downloadSupplierDocument(attributeCode: string, options?: AxiosRequestConfig) {
        return SupplierDocumentControllerApiFp(this.configuration).downloadSupplierDocument(attributeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save supplier document
     * @param {string} attributeCode 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierDocumentControllerApi
     */
    public saveSupplierDocument(attributeCode: string, file: File, options?: AxiosRequestConfig) {
        return SupplierDocumentControllerApiFp(this.configuration).saveSupplierDocument(attributeCode, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupplierRenewalControllerApi - axios parameter creator
 * @export
 */
export const SupplierRenewalControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingRenewals: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getPendingRenewals', 'productId', productId)
            const localVarPath = `/api/v1/supplier-renewal/renewals/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        populate: async (productId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('populate', 'productId', productId)
            const localVarPath = `/api/v1/supplier-renewal/populate/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} attribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renew: async (productId: string, attribute: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('renew', 'productId', productId)
            // verify required parameter 'attribute' is not null or undefined
            assertParamExists('renew', 'attribute', attribute)
            const localVarPath = `/api/v1/supplier-renewal/renew/{productId}/{attribute}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"attribute"}}`, encodeURIComponent(String(attribute)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplierRenewalControllerApi - functional programming interface
 * @export
 */
export const SupplierRenewalControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplierRenewalControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPendingRenewals(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SupplierRenewal>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPendingRenewals(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async populate(productId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.populate(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} attribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async renew(productId: string, attribute: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.renew(productId, attribute, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupplierRenewalControllerApi - factory interface
 * @export
 */
export const SupplierRenewalControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplierRenewalControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPendingRenewals(productId: string, options?: any): AxiosPromise<Array<SupplierRenewal>> {
            return localVarFp.getPendingRenewals(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        populate(productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.populate(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} productId 
         * @param {string} attribute 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renew(productId: string, attribute: string, options?: any): AxiosPromise<void> {
            return localVarFp.renew(productId, attribute, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupplierRenewalControllerApi - object-oriented interface
 * @export
 * @class SupplierRenewalControllerApi
 * @extends {BaseAPI}
 */
export class SupplierRenewalControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierRenewalControllerApi
     */
    public getPendingRenewals(productId: string, options?: AxiosRequestConfig) {
        return SupplierRenewalControllerApiFp(this.configuration).getPendingRenewals(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierRenewalControllerApi
     */
    public populate(productId: string, options?: AxiosRequestConfig) {
        return SupplierRenewalControllerApiFp(this.configuration).populate(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} productId 
     * @param {string} attribute 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierRenewalControllerApi
     */
    public renew(productId: string, attribute: string, options?: AxiosRequestConfig) {
        return SupplierRenewalControllerApiFp(this.configuration).renew(productId, attribute, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SupplierSettingsControllerApi - axios parameter creator
 * @export
 */
export const SupplierSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirm Agent AlkoEdu status for supplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAlkoEduStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/supplier-settings/alko-edu-confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supplier AlkoEdu status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlkoEduStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/supplier-settings/alko-edu-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supplier general settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/supplier-settings/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get listing instructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingInstructionUrl: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/supplier-settings/listing-instruction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get supplier permit number settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermitNumberSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/supplier-settings/permit-numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user manual url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserManual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/supplier-settings/user-manual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save supplier general settings
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGeneralSettings: async (requestBody: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('saveGeneralSettings', 'requestBody', requestBody)
            const localVarPath = `/api/v1/supplier-settings/general`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save supplier permit number settings
         * @param {PermitNumberSettings} permitNumberSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePermitNumberSettings: async (permitNumberSettings: PermitNumberSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'permitNumberSettings' is not null or undefined
            assertParamExists('savePermitNumberSettings', 'permitNumberSettings', permitNumberSettings)
            const localVarPath = `/api/v1/supplier-settings/permit-numbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(permitNumberSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupplierSettingsControllerApi - functional programming interface
 * @export
 */
export const SupplierSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SupplierSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirm Agent AlkoEdu status for supplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmAlkoEduStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmAlkoEduStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get supplier AlkoEdu status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlkoEduStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlkoEduStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAlkoEduStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get supplier general settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupplierSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get listing instructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListingInstructionUrl(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getListingInstructionUrl(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get supplier permit number settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPermitNumberSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PermitNumberSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPermitNumberSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user manual url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserManual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserManual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save supplier general settings
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGeneralSettings(requestBody: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGeneralSettings(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save supplier permit number settings
         * @param {PermitNumberSettings} permitNumberSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePermitNumberSettings(permitNumberSettings: PermitNumberSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePermitNumberSettings(permitNumberSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SupplierSettingsControllerApi - factory interface
 * @export
 */
export const SupplierSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SupplierSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirm Agent AlkoEdu status for supplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmAlkoEduStatus(options?: any): AxiosPromise<void> {
            return localVarFp.confirmAlkoEduStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supplier AlkoEdu status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlkoEduStatus(options?: any): AxiosPromise<AlkoEduStatus> {
            return localVarFp.getAlkoEduStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supplier general settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralSettings(options?: any): AxiosPromise<SupplierSettingsResponse> {
            return localVarFp.getGeneralSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get listing instructions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListingInstructionUrl(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getListingInstructionUrl(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get supplier permit number settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermitNumberSettings(options?: any): AxiosPromise<PermitNumberSettings> {
            return localVarFp.getPermitNumberSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user manual url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserManual(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getUserManual(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save supplier general settings
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGeneralSettings(requestBody: { [key: string]: object; }, options?: any): AxiosPromise<void> {
            return localVarFp.saveGeneralSettings(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save supplier permit number settings
         * @param {PermitNumberSettings} permitNumberSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePermitNumberSettings(permitNumberSettings: PermitNumberSettings, options?: any): AxiosPromise<void> {
            return localVarFp.savePermitNumberSettings(permitNumberSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SupplierSettingsControllerApi - object-oriented interface
 * @export
 * @class SupplierSettingsControllerApi
 * @extends {BaseAPI}
 */
export class SupplierSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Confirm Agent AlkoEdu status for supplier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public confirmAlkoEduStatus(options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).confirmAlkoEduStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supplier AlkoEdu status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public getAlkoEduStatus(options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).getAlkoEduStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supplier general settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public getGeneralSettings(options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).getGeneralSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get listing instructions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public getListingInstructionUrl(options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).getListingInstructionUrl(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get supplier permit number settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public getPermitNumberSettings(options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).getPermitNumberSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user manual url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public getUserManual(options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).getUserManual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save supplier general settings
     * @param {{ [key: string]: object; }} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public saveGeneralSettings(requestBody: { [key: string]: object; }, options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).saveGeneralSettings(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save supplier permit number settings
     * @param {PermitNumberSettings} permitNumberSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupplierSettingsControllerApi
     */
    public savePermitNumberSettings(permitNumberSettings: PermitNumberSettings, options?: AxiosRequestConfig) {
        return SupplierSettingsControllerApiFp(this.configuration).savePermitNumberSettings(permitNumberSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemMessageControllerApi - axios parameter creator
 * @export
 */
export const SystemMessageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissSystemMessage: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('dismissSystemMessage', 'id', id)
            const localVarPath = `/api/v1/system-messages/dismiss/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginMessage: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system-messages/login-message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadSystemMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/system-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemMessageControllerApi - functional programming interface
 * @export
 */
export const SystemMessageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemMessageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dismissSystemMessage(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dismissSystemMessage(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoginMessage(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginMessage(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadSystemMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SystemMessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadSystemMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemMessageControllerApi - factory interface
 * @export
 */
export const SystemMessageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemMessageControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dismissSystemMessage(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.dismissSystemMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginMessage(options?: any): AxiosPromise<string> {
            return localVarFp.getLoginMessage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadSystemMessages(options?: any): AxiosPromise<Array<SystemMessageDto>> {
            return localVarFp.getUnreadSystemMessages(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemMessageControllerApi - object-oriented interface
 * @export
 * @class SystemMessageControllerApi
 * @extends {BaseAPI}
 */
export class SystemMessageControllerApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessageControllerApi
     */
    public dismissSystemMessage(id: number, options?: AxiosRequestConfig) {
        return SystemMessageControllerApiFp(this.configuration).dismissSystemMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessageControllerApi
     */
    public getLoginMessage(options?: AxiosRequestConfig) {
        return SystemMessageControllerApiFp(this.configuration).getLoginMessage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemMessageControllerApi
     */
    public getUnreadSystemMessages(options?: AxiosRequestConfig) {
        return SystemMessageControllerApiFp(this.configuration).getUnreadSystemMessages(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenderControllerApi - axios parameter creator
 * @export
 */
export const TenderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get available product groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableProductGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tender/available-product-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tender details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTender: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTender', 'id', id)
            const localVarPath = `/api/v1/tender/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tenders with available filter options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderFilters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/tender/list-frame`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload tender related offer image
         * @param {string} attribute 
         * @param {File} file 
         * @param {string} [tenderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAndSaveFile: async (attribute: string, file: File, tenderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attribute' is not null or undefined
            assertParamExists('validateAndSaveFile', 'attribute', attribute)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('validateAndSaveFile', 'file', file)
            const localVarPath = `/api/v1/tender/files/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (tenderId !== undefined) {
                localVarQueryParameter['tenderId'] = tenderId;
            }

            if (attribute !== undefined) {
                localVarQueryParameter['attribute'] = attribute;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenderControllerApi - functional programming interface
 * @export
 */
export const TenderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get available product groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableProductGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilterItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableProductGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tender details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTender(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tender>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTender(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tenders with available filter options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenderFilters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenderListFrame>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenderFilters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload tender related offer image
         * @param {string} attribute 
         * @param {File} file 
         * @param {string} [tenderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateAndSaveFile(attribute: string, file: File, tenderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateAndSaveFile(attribute, file, tenderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenderControllerApi - factory interface
 * @export
 */
export const TenderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenderControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get available product groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableProductGroups(options?: any): AxiosPromise<Array<FilterItem>> {
            return localVarFp.getAvailableProductGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tender details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTender(id: string, options?: any): AxiosPromise<Tender> {
            return localVarFp.getTender(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tenders with available filter options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenderFilters(options?: any): AxiosPromise<TenderListFrame> {
            return localVarFp.getTenderFilters(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload tender related offer image
         * @param {string} attribute 
         * @param {File} file 
         * @param {string} [tenderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAndSaveFile(attribute: string, file: File, tenderId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.validateAndSaveFile(attribute, file, tenderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TenderControllerApi - object-oriented interface
 * @export
 * @class TenderControllerApi
 * @extends {BaseAPI}
 */
export class TenderControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get available product groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderControllerApi
     */
    public getAvailableProductGroups(options?: AxiosRequestConfig) {
        return TenderControllerApiFp(this.configuration).getAvailableProductGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tender details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderControllerApi
     */
    public getTender(id: string, options?: AxiosRequestConfig) {
        return TenderControllerApiFp(this.configuration).getTender(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tenders with available filter options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderControllerApi
     */
    public getTenderFilters(options?: AxiosRequestConfig) {
        return TenderControllerApiFp(this.configuration).getTenderFilters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload tender related offer image
     * @param {string} attribute 
     * @param {File} file 
     * @param {string} [tenderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenderControllerApi
     */
    public validateAndSaveFile(attribute: string, file: File, tenderId?: string, options?: AxiosRequestConfig) {
        return TenderControllerApiFp(this.configuration).validateAndSaveFile(attribute, file, tenderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get current (logged in) user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/get-current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAction: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAction: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SupplierRequest} supplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveSupplier: async (supplierRequest: SupplierRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'supplierRequest' is not null or undefined
            assertParamExists('setActiveSupplier', 'supplierRequest', supplierRequest)
            const localVarPath = `/api/v1/user/supplier`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(supplierRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get current (logged in) user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUserData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUserData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAction(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAction(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutAction(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutAction(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SupplierRequest} supplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setActiveSupplier(supplierRequest: SupplierRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setActiveSupplier(supplierRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Get current (logged in) user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUserData(options?: any): AxiosPromise<User> {
            return localVarFp.getCurrentUserData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAction(options?: any): AxiosPromise<void> {
            return localVarFp.loginAction(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutAction(options?: any): AxiosPromise<void> {
            return localVarFp.logoutAction(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SupplierRequest} supplierRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setActiveSupplier(supplierRequest: SupplierRequest, options?: any): AxiosPromise<User> {
            return localVarFp.setActiveSupplier(supplierRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get current (logged in) user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getCurrentUserData(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).getCurrentUserData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public loginAction(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).loginAction(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public logoutAction(options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).logoutAction(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SupplierRequest} supplierRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public setActiveSupplier(supplierRequest: SupplierRequest, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).setActiveSupplier(supplierRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserSettingsControllerApi - axios parameter creator
 * @export
 */
export const UserSettingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Save user settings
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings: async (userSettings: UserSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userSettings' is not null or undefined
            assertParamExists('getUserSettings', 'userSettings', userSettings)
            const localVarPath = `/api/v1/user-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/user-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserSettingsControllerApi - functional programming interface
 * @export
 */
export const UserSettingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserSettingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Save user settings
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserSettings(userSettings: UserSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSettings(userSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserSettingsControllerApi - factory interface
 * @export
 */
export const UserSettingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserSettingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary Save user settings
         * @param {UserSettings} userSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserSettings(userSettings: UserSettings, options?: any): AxiosPromise<UserSettings> {
            return localVarFp.getUserSettings(userSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSettings(options?: any): AxiosPromise<UserSettings> {
            return localVarFp.saveSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserSettingsControllerApi - object-oriented interface
 * @export
 * @class UserSettingsControllerApi
 * @extends {BaseAPI}
 */
export class UserSettingsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Save user settings
     * @param {UserSettings} userSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsControllerApi
     */
    public getUserSettings(userSettings: UserSettings, options?: AxiosRequestConfig) {
        return UserSettingsControllerApiFp(this.configuration).getUserSettings(userSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserSettingsControllerApi
     */
    public saveSettings(options?: AxiosRequestConfig) {
        return UserSettingsControllerApiFp(this.configuration).saveSettings(options).then((request) => request(this.axios, this.basePath));
    }
}


