import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from './useAxiosPrivate';
import { LISTING_INSTRUCTIONS } from '../constants/queryKeys';

export default function useListingInstructionDocument() {
  const axios = useAxiosPrivate();

  const { i18n } = useTranslation();

  async function queryFn(): Promise<string> {
    const res = await axios.get(`/supplier-settings/listing-instruction?locale=${i18n.language}`);
    return res.data?.listingInstructionUrl;
  }

  return useQuery({
    queryKey: [LISTING_INSTRUCTIONS],
    queryFn,
  });
}
