/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import classNames from 'classnames';
import { Divider } from 'primereact/divider';
import { Contact, OfferTargetEnum, SaveContactRequest } from '../../api/_generated_';
import LinkText from '../LinkText';
import OfferTargetSelect from '../OfferTargetSelect';
import ContactForm from '../ContactForm';
import { getEnv } from '../../env';
import { Selection } from '../types/types';

const env = getEnv();

type Props = {
  onSubmit(formData: any): void;
  onAddContact(data: any): void;
  contacts?: Contact[];
  isLoading?: boolean;
  isTender?: boolean;
  selection: Selection,
  isAgent: boolean,
  warehouseLicenceNumber?: string
  listingInstructionUrl?: string;
};

export default function CreateOfferForm({
  onSubmit,
  onAddContact,
  contacts,
  isLoading,
  isTender,
  selection,
  isAgent,
  warehouseLicenceNumber,
  listingInstructionUrl,
}: Props) {
  const { t } = useTranslation();

  const [displayBasic, setDisplayBasic] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      acceptTerms: false,
      contactId: undefined,
      target: undefined,
      attributes: undefined,
      useListingService: false,
      locallyAvailableT3: false,
    },
  });

  // Map contacts as PrimeReact SelectItem because otherwise view is exploding when changing value for some reason
  const contactSelectItems = useMemo(() => contacts?.map((contact: Contact) => ({
    label: `${contact.firstName} ${contact.lastName}`,
    value: contact.id,
  })), [contacts]);

  const handleAddContact = (data: SaveContactRequest) => {
    onAddContact(data);
    setDisplayBasic(false);
  };

  const locallyAvailableT3 = watch('locallyAvailableT3');

  useEffect(() => {
    if (locallyAvailableT3) {
      setValue('target', OfferTargetEnum.Store as any);
    } else {
      setValue('target', undefined);
    }
  }, [locallyAvailableT3, setValue]);

  useEffect(() => {
    if (isAgent && warehouseLicenceNumber && Selection.SaleToOrderSelection === selection) {
      setValue('target', OfferTargetEnum.CentralWarehouse as any);
    }
  }, [isAgent, warehouseLicenceNumber, selection, setValue]);

  const showLocallyAvailableT3 = !isAgent && !isTender
    && Selection.SaleToOrderSelection === selection;

  const notSpecialOrder = selection !== Selection.SpecialOrder;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        header={t('settings.cmd-add-contact')}
        visible={displayBasic}
        modal
        breakpoints={{ '960px': '75vw', '640px': '100vw' }}
        style={{ width: '50vw' }}
        onHide={() => setDisplayBasic(false)}
      >
        <ContactForm
          isSaving={false}
          onCancel={() => setDisplayBasic(false)}
          onSubmit={handleAddContact}
          onDelete={() => {}}
        />
      </Dialog>

      {showLocallyAvailableT3 && (
        <div className="field flex align-items-center gap-3">
          <Controller
            name="locallyAvailableT3"
            control={control}
            render={({ field, fieldState }) => (
              <Checkbox
                inputId="locallyAvailableT3"
                onChange={(e) => field.onChange(e.checked)}
                checked={field.value}
                className={classNames({ 'p-invalid': fieldState.error })}
              />
            )}
          />
          <label htmlFor="locallyAvailableT3" className={classNames({ 'mb-0': true, 'p-error': errors.locallyAvailableT3 })}>
            {t('offers.label-locally-available-t3')}
          </label>
        </div>
      )}

      <Controller
        name="target"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <OfferTargetSelect
            onChange={field.onChange}
            value={field.value}
            error={!!fieldState.error}
            isTender={isTender}
            isT3={locallyAvailableT3}
            isAgent={isAgent}
            warehouseLicenceNumber={warehouseLicenceNumber}
            selection={selection}
          />
        )}
      />

      <Controller
        name="contactId"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState }) => (
          <>
            <label htmlFor={field.name} className={classNames({ 'p-error': fieldState.error })}>{t('settings.label-contact')}</label>
            <div className="p-inputgroup mb-5">
              <Dropdown
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={contactSelectItems}
                className={classNames({ 'w-full': true, 'p-invalid': fieldState.error })}
              />
              <Button
                type="button"
                icon="pi pi-plus"
                severity="secondary"
                style={{ border: '1px solid #eaeaea' }}
                onClick={() => setDisplayBasic(true)}
              />
            </div>
          </>
        )}
      />

      <Divider />

      { !isTender && notSpecialOrder && (
        <div className="field flex align-items-center gap-3">
          <Controller
            name="useListingService"
            control={control}
            render={({ field, fieldState }) => (
              <Checkbox
                inputId="useListingService"
                onChange={(e) => field.onChange(e.checked)}
                checked={field.value}
                className={classNames({ 'p-invalid': fieldState.error })}
              />
            )}
          />
          <label htmlFor="useListingService" className={classNames({ 'mb-0': true, 'p-error': errors.useListingService })}>
            {t('offers.label-listing-service-included')}
          </label>
        </div>
      )}

      <div className="form-group flex align-items-center gap-3">
        <Controller
          name="acceptTerms"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <Checkbox
              inputId="acceptTerms"
              onChange={(e) => field.onChange(e.checked)}
              checked={field.value}
              className={classNames({ 'p-invalid': fieldState.error })}
            />
          )}
        />
        <label htmlFor="acceptTerms" className={classNames({ 'mb-0': true, 'p-error': errors.acceptTerms })}>
          <Trans
            i18nKey="offers.label-accept-terms"
            components={{
              link1: <LinkText to={listingInstructionUrl} title="valikoimaanotto-ohjeen" />,
            }}
          />
        </label>
      </div>
      <div className="text-right">
        <Button
          type="submit"
          label={t('common.cmd-start')}
          icon="pi pi-arrow-right"
          iconPos="right"
          loading={isLoading}
        />
      </div>
    </form>
  );
}
