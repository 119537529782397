import React, { ChangeEvent } from 'react';
import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import SearchInput from '../SearchInput';
import { BeverageStrength, PurchasePriceFilterProps } from '../types/types';
import useFileDownload from '../../hooks/useFileDownload';

type Props = {
  loading?: boolean;
  filters?: PurchasePriceFilterProps;
  onChangeSearch(event: ChangeEvent<HTMLInputElement>): void;
  onChangeBeverageStrength?(e: SelectButtonChangeEvent): void;
};

export default function PurchasePriceFilters({
  loading,
  filters,
  onChangeSearch,
  onChangeBeverageStrength,
}: Props) {
  const { t, i18n } = useTranslation();

  const beverageStrengthOptions = [
    { label: t('purchase-prices.label-filter-mild'), value: BeverageStrength.MILD },
    { label: t('purchase-prices.label-filter-strong'), value: BeverageStrength.STRONG },
  ];

  const handleDownloadResponse = (file: File) => {
    const blob = new Blob([file], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const tempLink = document.createElement('a');
    tempLink.href = window.URL.createObjectURL(blob);
    tempLink.download = `report_${filters?.beverageStrength?.toLowerCase()}_${new Date().toISOString().slice(0, 10)}.xlsx`;
    tempLink.click();
  };

  const { onDownload } = useFileDownload({
    callback: handleDownloadResponse,
    url: `/purchase-prices/export?beverageStrength=${filters?.beverageStrength?.toLowerCase()}&locale=${i18n.language}`,
  });

  return (
    <div className="flex flex-wrap justify-content-between gap-3 mb-3">
      <div className="flex-1">
        <SearchInput
          placeholder={t('products.label-search-products')}
          loading={loading}
          value={filters?.search}
          onChange={onChangeSearch}
          style={{ height: '50px', minWidth: '16rem' }}
        />
      </div>
      <SelectButton
        value={filters?.beverageStrength}
        options={beverageStrengthOptions}
        onChange={onChangeBeverageStrength}
      />
      <Button
        label={t('common.cmd-export')}
        size="small"
        outlined
        type="button"
        icon="pi pi-file-export"
        onClick={onDownload}
        disabled={!filters?.beverageStrength}
      />
    </div>
  );
}
