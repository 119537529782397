import { useQuery } from '@tanstack/react-query';
import { UNREAD_MESSAGES_COUNT } from '../constants/queryKeys';
import useAxiosPrivate from './useAxiosPrivate';
import useAuthStore from '../stores/AuthStore';

export default function useUnreadMessages() {
  const { user } = useAuthStore();
  const axios = useAxiosPrivate();

  async function fetchData() {
    const res = await axios.get('/messages/unread/count');
    return res.data;
  }

  return useQuery({
    queryKey: [UNREAD_MESSAGES_COUNT],
    queryFn: fetchData,
    enabled: !user?.agent,
    refetchInterval: 300000, // 5 minutes,
  });
}
