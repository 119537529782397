import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { PriceData } from '../../api/_generated_';
import './PriceQueryResults.scss';
import { priceCalcluationAttributes, PriceResult } from '../OfferForm/PriceCalculator/PriceCalculator';

type Props = {
  data: PriceData | undefined;
  isLoading?: boolean;
};

export default function PriceQueryResults({
  data,
  isLoading,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="price_calculator">
      <div className="price_calculator__header">
        <span>{t('prices.label-price-calculator')}</span>
        <Button
          label={t('prices.cmd-calculate-price')}
          severity="success"
          icon="pi pi-calculator"
          size="small"
          loading={isLoading}
        />
      </div>
      <PriceResult data={data} isLoading={isLoading} attributes={priceCalcluationAttributes} />
    </div>
  );
}
